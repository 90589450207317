import React from "react";
import { Bar } from "react-chartjs-2";

interface RegistrationChartProps {
  data: number[];
}

export const RegistrationChart: React.FC<RegistrationChartProps> = ({ data }: RegistrationChartProps) => {
  const options = {
    responsive: true,
    plugins: {
      labels: { 
        render: (args: any) => {
          const v = args.value;
          return `${v}명`;
        }
      },
    },
  };

  return <Bar options={options} data={data}  />;
};