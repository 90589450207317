export const avatarUrl = (url: string | null): string => {
  return url ?? 'https://cdn.fleaauction.world/images/user.png'
}

export const phoneNumber = (phone: string | null): string => {
  if (phone === null) {
    return '미입력';
  }

  const matches = phone?.match(/(\d{3})(\d{4})(\d+)/)
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`
  }
  return '비정상 번호'
}
