import {
  Card,
  Col,
  Create,
  Form,
  Input,
  Row,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { IEvent } from "interfaces";

export const EventCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<IEvent>();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} layout="vertical">
        <Card
          type="inner"
          title="이벤트 생성"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="이벤트 제목"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="이벤트 부제목"
                name="subtitle"
                rules={[{required: false}]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  );
};
