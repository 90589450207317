import {
  Form, FormProps, InputNumber, Input, Select, Modal, ModalProps
} from "@pankod/refine-antd";

import { IEventButton } from "interfaces";

type AddButtonModalProps = {
  modalProps: ModalProps
  formProps: FormProps
  callback: (i: IEventButton) => void
};

export const AddButtonModal: React.FC<AddButtonModalProps> = ({
  modalProps,
  formProps,
  callback,
}) => {

  return (
    <Modal {...modalProps} title="버튼 정보 입력" destroyOnClose={true} width={480}>
      <Form
        {...formProps}
        onFinish={(v) => {
          const newButton = {
            label: v.label,
            buttonType: v.buttonType,
            location: v.location,
            target: v.target,
            targetId: v.targetId ?? null,
          } as IEventButton;
          callback(newButton);
          modalProps.visible = false;
        }}
        layout="vertical"
      >
        <Form.Item
          key={`label`}
          name={`label`}
          label="버튼 텍스트"
          rules={[{required: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={`button-type`}
          name={`buttonType`}
          label="버튼 타입"
          rules={[{required: true}]}
        >
          <Select
            options={[
              { label: '플리색상/검정글자', value: 'info', },
              { label: '노란바탕/검정글자', value: 'warn', },
              { label: '빨간바탕/흰글자', value: 'error', },
              { label: '녹색바탕/흰글자', value: 'success', },
            ]}
          />
        </Form.Item>
        <Form.Item
          key={`location`}
          name={`location`}
          label="버튼 위치"
          rules={[{required: true}]}
        >
          <Select
            options={[
              { label: '정중앙', value: 'center', },
              { label: '하단 왼쪽', value: 'bottom-left', },
              { label: '하단 오른쪽', value: 'bottom-right', },
              { label: '하단 중앙', value: 'bottom-center', },
              { label: '상단 왼쪽', value: 'top-left', },
              { label: '상단 오른쪽', value: 'top-right', },
              { label: '상단 중앙', value: 'top-center', },
            ]}
          />
        </Form.Item>
        <Form.Item
          key={`target`}
          name={`target`}
          label="이동 타겟"
          rules={[{required: true}]}
        >
          <Select
            options={[
              { label: '홈', value: 'home', },
              { label: '기획전', value: 'packs', },
              { label: '옥션', value: 'auctions', },
              { label: '작가', value: 'artists', },
              { label: '아티클', value: 'articles', },
              { label: '마이페이지', value: 'mypage', },
              { label: '공지사항', value: 'mypage/news', },
              { label: '작품판매문의', value: 'mypage/inquiry', },
              { label: '구매내역', value: 'mypage/orders', },
              { label: '쿠폰', value: 'mypage/coupons', },
            ]}
          />
        </Form.Item>
        <Form.Item
          key={`targetId`}
          name={`targetId`}
          label="이동 타겟 아이디"
          rules={[{required: false}]}
        >
          <InputNumber
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
