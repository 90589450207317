import {
  Avatar,
  DeleteButton,
  getDefaultSortOrder,
  List,
  NumberField,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DEFAULT_AVATAR_URL } from "../../constants";
import { ISurvey } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const SurveyList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<ISurvey>({
    // initialSorter: [],
    initialPageSize: 20,
    syncWithLocation: true
  });
  return (
    <List>
      {
        tableProps?.dataSource && (tableProps?.dataSource as any)?.data &&
        <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
          <Table.Column<ISurvey>
            dataIndex="id"
            key="id"
            title="ID"
            render={(value) => <NumberField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column<ISurvey>
            dataIndex="image"
            title="사용자"
            render={(_, record) => (
              <Link to={`/surveys/show/${record?.id}`}>
                <Avatar
                  size={64}
                  shape="square"
                  src={record?.user?.avatar ?? DEFAULT_AVATAR_URL}
                  alt={record?.user?.username ?? 'user'}
                />
              </Link>
            )}
          />
          <Table.Column<ISurvey>
            dataIndex="username"
            key="username"
            title="닉네임"
            render={(_, record) => <>
              <div>
                { record?.user?.gender === 'M' && <Avatar size={24} shape="square" src="https://cdn.fleaauction.world/images/gender-male.png" alt="male" /> }
                { record?.user?.gender === 'F' && <Avatar size={24} shape="square" src="https://cdn.fleaauction.world/images/gender-female.png" alt="female" /> }
                { record?.user?.gender === null && <Avatar size={24} shape="square" src="https://cdn.fleaauction.world/images/gender-unknown.png" alt="unknown" /> }
                <TextField
                  value={record?.user?.username}
                  delete={record?.user?.deletedAt !== null}
                  style={
                    record?.user?.deletedAt !== null
                    ? { marginLeft: 4, fontWeight: "bold", color: "red" }
                    : { marginLeft: 4, fontWeight: "bold" }}
                  />
                </div>
                <TextField value={record?.user?.email} style={ record?.user?.isActive ? { color: "green"} : {}} />
            </>}
          />
          <Table.Column<ISurvey>
            dataIndex="isFixed"
            key="isFixed"
            title="설문내용"
            render={(_, record) => {
              return record?.comment
                ? <TextField value={record.comment} />
                : <TextField value={record.comment} />
            }}
          />
          <Table.Column<ISurvey>
            dataIndex="artistIds"
            key="artistIds"
            title="작가알림"
            render={(_, record) => <TagField value={`${record.user?.artistsSubscribed?.length ?? 0}명`} />}
          />
          <Table.Column<ISurvey>
            dataIndex="createdAt"
            key="createdAt"
            title="생성일"
            render={(value) => {
              return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
            }}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column<ISurvey>
            dataIndex="actions"
            title="메뉴"
            render={(_, record) => (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <DeleteButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      }
    </List>
  );
};
