import { RetweetOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Checkbox,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  message,
  NumberField,
  ShowButton,
  Space,
  Table, TagField, TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useUpdate } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dayjs from 'dayjs';
import { IBanner } from "interfaces";
import { useState } from "react";

const { Link } = routerProvider;

export const BannerList: React.FC<IResourceComponentsProps> = () => {
  const [ids, setIds] = useState<number[]>([]);
  const { tableProps, sorter, tableQueryResult } = useTable<IBanner>({
    initialPageSize: 20,
    syncWithLocation: true
  });
  const now = (new Date()).getTime();

  const { mutate } = useUpdate();

  return (
    <List
        headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<RetweetOutlined />}
            onClick={(): void => {
              if (ids.length < 2) {
                message.error('순서를 바꾸고 싶은 2개 아이템을 선택하세요.');
              } else {
                console.log(`selected: ${ids}`);
                setIds([]);
                mutate({
                  resource: "banners",
                  values: {
                      ids: ids,
                  },
                  id: 'swap',
                  invalidates: ['list'],

                },
                {
                  onSuccess: (data, variables, context) => {
                    tableQueryResult.refetch();
                  }
                });
              }
            }}
          >Swap</Button>
          {defaultButtons}
        </>
      )}
    >
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={(tableProps?.dataSource as any)?.data}
      >
        <Table.Column<IBanner>
          dataIndex="id"
          key="id"
          title="ID"
          render={(_, record) => <TextField key={record.id} value={record.id} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IBanner>
          dataIndex="name"
          key="name"
          title="배너명"
          render={(_, record) => (
            <div>
              <div>
                <TextField value={`${record.title}`} />
              </div>          
              <Link to={`/banners/show/${record?.id}`}>
                <Space>
                {
                  record?.image
                    ? <Avatar size={64} shape="square" src={record?.image} alt={record?.title} />
                    : <Avatar size={64} shape="square" alt={record?.title} />
                }
                {
                  record?.mobileImage
                    ? <Avatar size={64} shape="square" src={record?.mobileImage} alt={record?.title} />
                    : record?.bannerType === 'hero' ? <Avatar size={64} shape="square" alt={record?.title} /> : <></>
                }
                </Space>
              </Link>
            </div>
          )}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IBanner>
          dataIndex="target"
          key="target"
          title="이동 타겟"
          render={(_, record) => 
            <>
            {record?.target ? <TextField value={record?.target} /> : <TagField color='red' value={`이동 없음`} />}
            {record?.target && record?.targetId ? <TextField value={`#${record?.targetId}`} style={{ marginLeft: 8 }} /> : ``}
            </>
          }
        />
        <Table.Column<IBanner>
          dataIndex="buttonLabel"
          key="buttonLabel"
          title="버튼 레이블"
          render={(_, record) => <>{
            record?.bannerType === 'popup' ? <TextField value={`${record?.buttonLabel}`} />
            : <TagField color='red' value={`버튼 없음`} />
          }</>}
        />
        <Table.Column<IBanner>
          dataIndex="sortIndex"
          key="sortIndex"
          title="정렬순서"
          render={(_, record) =>
          <div key={record.id}>
            <Checkbox checked={ids.includes(record.id)} onChange={(event) => {
              if (event.target.checked) {
                setIds([...ids, record.id]);
              } else {
                setIds(ids.filter((v) => v !== record.id));
              }
            }}>{record.sortIndex}</Checkbox>
          </div>
        }
        />
        <Table.Column<IBanner>
          dataIndex="isActive"
          key="isActive"
          title="활성상태"
          render={(_, record) => 
            <>
            {
              record?.isActive ? <TagField color='green' value='활성' /> : <TagField value='비활성' />
            }
            {
              record?.startedAt && record?.endedAt && <>
                <div>
                  <TextField style={{fontSize: 10}} value={dayjs(record.startedAt).format('M월D일 H:mm')} />
                  { ' ~ ' }
                  <TextField style={{fontSize: 10}} value={dayjs(record.endedAt).format('M월D일 H:mm')} />
                </div>
              </>
            }
            </>
          }
        />
        <Table.Column<IBanner>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
