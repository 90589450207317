import {
  Avatar,
  Card,
  Col,
  Image,
  MarkdownField,
  Row,
  Show,
  Tag,
  Typography
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { HttpError, IResourceComponentsProps, useMany, useShow } from "@pankod/refine-core";
import { AgeFromDateString } from "age-calculator";
import { avatarUrl, phoneNumber } from "helpers/user";

import { IArtist, ISurvey } from "interfaces";
import { useEffect, useState } from "react";
import { countryName, sellerTypeName } from "helpers/seller";
import { genreName } from "helpers/artwork";
import { DEFAULT_AVATAR_URL } from "./../../constants";

const { Link } = routerProvider;

const { Title, Text } = Typography;

export const SurveyShow: React.FC<IResourceComponentsProps> = () => {
  // useShow
  const { queryResult } = useShow<ISurvey>();
  const { data: surveyData, isLoading: isLoadingSurvey } = queryResult;
  const record = surveyData?.data;
  const age = record?.user?.dob ? new AgeFromDateString(record?.user.dob).age : null;

  const [ids, setIds] = useState<number[]>([]);

  const { data: artistsData, refetch } = useMany<IArtist, HttpError>({
    resource: "artists/many",
    ids: ids,
  });

  const artists = artistsData?.data;
  console.log(`ids ${ids} =>`, artists);

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const ids = record?.user?.artistsSubscribed ?? [];
    const artistIds = ids.map((v) => v.artistId);
    setIds(artistIds);
    refetch();
  }, [record]);

  return (
    <Show isLoading={isLoadingSurvey}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card
        type="inner"
        title={`설문응답 회원`}
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            {
              record &&
              <Image
                width={64}
                src={record?.user?.avatar ?? DEFAULT_AVATAR_URL}
                alt={record?.user?.realname}
              />
            }
          </Col>
          <Col span={20}>
            <p>{record?.user?.deletedAt ? <Tag color='red'>탈퇴회원</Tag> : <></>}</p>
            <p>{record?.user?.isBanned ? <Tag color='red'>정지회원</Tag> : <></>}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>닉네임</Title>
            <Text delete={record?.user?.deletedAt !== null}>{record?.user?.username ?? 'n/a'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일</Title>
            <Text style={ record?.user?.isActive ? { color: "green"} : {}}>{record?.user?.email}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전화번호</Title>
            {
              record &&
              <Text>{phoneNumber(record?.user?.phone ?? '')}</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>실명</Title>
            {record?.user?.realname ? <Text>{record?.user?.realname}</Text> : <Text>n/a</Text>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>성별</Title>
            <Text>{record?.user?.gender ? (record?.user.gender === 'M' ? <Text>남자</Text> : <Text>여자</Text>)  : 'n/a'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>나이</Title>
            <Text>{age ? `만${age}세` : 'n/a'}</Text>
          </Col>
        </Row>
      </Card>
      <Card
        type="inner"
        title={`설문시 선택한 작가리스트`}
        style={{marginBottom: 24}}
      >
        {
          artists && artists.length < 1 &&
            <Row gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={24}><Text>선택 작가 없음</Text></Col>
            </Row>
        }
        {
          artists && artists.map((artist: IArtist) => 
            <Row key={artist.id} gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={8}>
                <Title level={5} style={{fontSize: 11}}>닉네임</Title>
                {
                  artist && artist.user &&
                  <Avatar
                    size={32}
                    src={avatarUrl(artist?.user?.avatar)}
                    alt={artist?.user?.realname}
                  />
                }
                <span style={{marginLeft: 8}}>
                  작가: <Link to={`/artists/show/${artist?.id}`}><Tag>{artist?.name ?? 'n/a'}</Tag></Link>
                  유저: <Link to={`/users/show/${artist?.user?.id}`}><Tag>{artist?.user?.username ?? 'n/a'}</Tag></Link>
                </span>
              </Col>
              <Col span={4}>
                <Title level={5} style={{fontSize: 11}}>장르</Title>
                <Text>{genreName(artist?.genre)}</Text>
              </Col>
              <Col span={4}>
                <Title level={5} style={{fontSize: 11}}>구분</Title>
                <Text>{sellerTypeName(artist?.sellerType)}</Text>
              </Col>
              <Col span={4}>
                <Title level={5} style={{fontSize: 11}}>국적</Title>
                <Text>{countryName(artist?.nationality)}</Text>
              </Col>
              <Col span={4}>
                <Title level={5} style={{fontSize: 11}}>성별</Title>
                {artist?.user?.gender ? (artist?.user?.gender === 'F' ? <Text>여자</Text> : <Text>남자</Text>)  : '정보없음'}
              </Col>
            </Row>
          )
        }
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>코멘트</Title>
            {
              record?.comment
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.comment} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>        
      </Card>
    </Show>
  )
};
