import { message } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { useState } from "react";

message.config({
  maxCount: 5, // 한 번에 표시되는 최대 메시지 개수
});

export const CsvImportForm: React.FC<IResourceComponentsProps> = () => {
  // useState hook
  const [file, setFile] = useState();
  const [array, setArray] = useState<any>([]);
  const fileReader = new FileReader();

  const { mutateAsync } = useUpdate();

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const removeFirstLine = (inputString: string): string => {
    const carriageReturnIndex = inputString.indexOf('\n');
    if (carriageReturnIndex !== -1) {
        return inputString.substring(carriageReturnIndex + 1);
    } else {
        return inputString;
    }
  }

  const getInteger = (text: string | null): number => {
    if (!text) {
      return 0;
    }
    const v = parseInt(text.trim().replace(/["¸]+/g, '').replace(/[주]/g, '')); // 1주 2주... 때문에 추가
    return (isNaN(v)) ? 0 : v;
  }

  const csvFileToArray = async (fullText: string) => {
    // const textFirstLineRemoved = removeFirstLine(fullText)
    const replacedString = fullText.replace(/"([\w\W]*?)"/gi, function(match, token) {
      return match.replace(/\n/g, '§').replace(/,/g, '¸').replace(/\r/g, '');
    });
    const csvHeader = replacedString.slice(0, replacedString.indexOf("\n")).split(",");
    const csvRows = replacedString.slice(replacedString.indexOf("\n") + 1).split("\n");

    console.log('csv header => ', csvHeader)
    console.log('csv rows => ', csvRows)

    const rows = csvRows.map((row) => {
      const values = row.split(",");
      const obj = csvHeader.reduce((object: any, header, index) => {
        const key = header.replace(/[^a-zA-Z]/g, '');
        object[key] = values[index] ? values[index].trim() : null;
        return object;
      }, {});
      return obj;
    });

    setArray(rows);
    const values = rows.filter((v) => {
      return v && v.hasOwnProperty('id') && v['id']
    }).map((v) => {

      const id = getInteger(v['id']);
      const bgIndex = v['backgroundIndex'] ? getInteger(v['backgroundIndex'].replace(/\D/g, '')) : 0;
      const individuals = v['credentials'] ? v['credentials'].replace(/^"|"$/g, '').replace(/§/g, ' \n').replace(/¸/g, ',').replace(/(\n)(\d{4})/g, "$1- $2").replace(/\n- /g, '\n') : null;

      return {
        id: id,
        backgroundIndex: bgIndex,
        credentials: individuals,
        groups: v['groups'] ? v['groups'].replace(/^"|"$/g, '').replace(/§/g, ' \n').replace(/¸/g, ',').replace(/(\n)(\d{4})/g, "$1- $2").replace(/\n- /g, '\n') : null,
        others: v['others'] ? v['others'].replace(/^"|"$/g, '').replace(/§/g, ' \n').replace(/¸/g, ',').replace(/(\n)(\d{4})/g, "$1- $2").replace(/\n- /g, '\n') : null,
      };
    });
    console.log(`values =>`, values);

    await uploadItems(values);
  };

  const uploadItems = async (items: any[]) => {
    for (const item of items) {
      try {
        const data = await mutateAsync({
          resource: 'artists',
          values: {
            backgroundIndex: item.backgroundIndex,
            credentials: item.credentials,
            groups: item.groups,
            others: item.others,
          },
          id: item.id,
        });
        console.log(data);
      } catch (error) {
        message.error(`실패: ${error}`);
        break;
      }
    }
  };

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = async function (event: any) {
        const text = event.target.result;
        await csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1>CSV 파일로 입력</h1>
        <form>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          />

          <button
            onClick={(e) => {
              handleOnSubmit(e);
            }}
          >
            IMPORT CSV
          </button>
        </form>

        <br />

        <table>
          <thead>
            <tr>
              {
                headerKeys.map((key, i) => (
                  <th key={`key-${i}`}>{key}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              array.map((v: any, i: number) => (
                <tr key={`key-v-${i}`}>
                {Object.values(v).map((w: any, j: number) => (<td key={`key-v-${i}-${j}`}>{w}</td>))}
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  );
};
