import { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Resizer from 'react-image-file-resizer';
import { Modal, RcFile, Spin, UploadFile } from '@pankod/refine-antd';
import { ACCESS_TOKEN } from "../../../constants";
import { useApiUrl } from '@pankod/refine-core';
import { getBase64 } from 'helpers/utils';
import { IArtwork } from 'interfaces';

const { Dragger } = Upload;

interface ImageUploaderProps {
  artwork: IArtwork,
  bg: string,
  uploadFiles: UploadFile[],
  uploadHandler: (items: UploadFile[]) => void
}

const ImageUploader = ({ artwork, bg, uploadFiles, uploadHandler }: ImageUploaderProps) => {
  // useState hooks
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [endpoint, setEndpoint] = useState('')

  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  //? 업로드 전 이미지 압축
  const handleBeforeUpload = async (file: File) => {
    console.log(`bg => ${bg}`);

    return new Promise<File>((resolve, reject) => {
      const format = (file.type === 'image/png') ? 'PNG' : 'JPEG';
      if (bg === 'nil') {
        const maxSize = 1280;
        Resizer.imageFileResizer(
          file as RcFile,
          maxSize, // maxWidth
          maxSize, // maxHeight
          format, // compressFormat
          88, // quality
          0, // rotation
          (uri: any) => {
            if (uri) {
              const compressedFile = new File([uri], file.name, { type: 'image/jpeg' });
              resolve(compressedFile);
            } else {
              reject(new Error('이미지 압축 중 오류가 발생했습니다.'));
            }
          },
          'file' // outputType
        );
      } else {
        const maxSize = 640;
        Resizer.imageFileResizer(
          file as RcFile,
          maxSize, // maxWidth
          maxSize, // maxHeight
          format, // compressFormat
          90, // quality
          0, // rotation
          (uri: any) => {
            if (uri) {
              const compressedFile = new File([uri], file.name, { type: 'image/png' });
              resolve(compressedFile);
            } else {
              reject(new Error('이미지 압축 중 오류가 발생했습니다.'));
            }
          },
          'file' // outputType
        );
      }
    });
  };

  const handlePreviewModalClose = () => setPreviewOpen(false);

  //? file drop handler
  const onChangeUploadHandler = async ({ fileList }: any) => {
    setIsUploading(true);
    const newFileList = await Promise.all([...fileList].map(async(file) => {
      console.log(file);
      if (file.response) {
        file.url = await file.response.data;
      }
      return file;
    }));
    setIsUploading(false);

    uploadHandler(newFileList);
  };

  //? file drop handler
  const onChangeComposeHandler = async ({ fileList, file }: any) => {
    setIsUploading(true);
    file.name = `합성중...`;
    const newFileList = await Promise.all([...fileList].map(async(file) => {
      console.log(file);
      if (file.response) {
        const imageUrl = await file.response.data;
        file.url = imageUrl;
        file.thumbUrl = imageUrl;
        file.name = imageUrl.replace(/^.*[\\/]/, '');
      }
      return file;
    }));

    setIsUploading(false);

    uploadHandler(newFileList);
  };

  const onPreviewHandler = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(`작품명: ${artwork.title}`);
  };

  // const deleteHandler = async (file: UploadFile) => {
  //   const src = file.url as string;
  //   if (src) {
  //     const newFileList = fileList.filter((e) => e.url !== src);
  //     try {
  //       const input = {
  //         Bucket: `auction-uploads`,
  //         Key: src.replace('https://cdn.fleaauction.world/', ''),
  //       }
  //       const command = new DeleteObjectCommand(input);
  //       await s3Client.send(command);
  //     } catch (e) {
  //       console.error(e, 'removing image(s) from S3 failed.')
  //     }
  //     setFileList(newFileList);
  //   }
  // };

  useEffect(() => {
    if (bg === 'nil') {
      setEndpoint(`${apiUrl}/artworks/${artwork?.id}/image`)
    } else {
      setEndpoint(`${apiUrl}/artworks/${artwork?.id}/compose/${bg}`)
    }
  }, [bg]);


  return (
    <div>
      <Dragger
        beforeUpload={handleBeforeUpload}
        onChange={bg === 'nil' ? onChangeUploadHandler : onChangeComposeHandler}
        // onDelete={onDeleteHandler}
        onPreview={onPreviewHandler}
        fileList={uploadFiles}
        listType={'picture'}
        multiple
        action={endpoint}
        headers={headers}
        showUploadList={true}
        accept="image/*"
      >
        <p className="ant-upload-drag-icon">
          { isUploading ? <Spin /> : <InboxOutlined style={{fontSize: 24}} /> }
        </p>
        <p className="ant-upload-text">이미지를 드래그 앤 드롭하거나 클릭하여 업로드하세요</p>
      </Dragger>
      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handlePreviewModalClose}
      >
        <img alt="artwork" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default ImageUploader;
