import {
  Avatar, Card, Col, Image, MarkdownField, Row, Show, Tag, Typography
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { AgeFromDateString } from "age-calculator";
import { categoryName, firstImageUrl, genreName } from "helpers/artwork";
import { bankName } from "helpers/bank";
import { countryName, sellerTypeName } from "helpers/seller";
import { avatarUrl, phoneNumber } from "helpers/user";
import { IArtist, IArtwork } from "interfaces";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;
export const ArtistShow: React.FC<IResourceComponentsProps> = () => {
  const [artworks, setArtworks] = useState<IArtwork[]>([])
  const { show: goto } = useNavigation();
  const { queryResult } = useShow<IArtist>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const age = record?.user?.dob ? new AgeFromDateString(record?.user?.dob).age : null;

  useEffect(() => {
    if (record) {
      if (record?.sellerType === 'RESELLER') {
        setArtworks(record?.owningArtworks ?? [])
      } else {
        setArtworks(record?.artworks ?? [])
      }

    }
  }, [record])


  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card
        type="inner"
        title="사용자 기본정보"
        style={{marginBottom: 24}}
        extra={
          <>
            { <Tag color='green'>참여지수 {record?.fleaauctionIndex}</Tag> }
            { <Tag color='green'>이력지수 {record?.backgroundIndex}</Tag> }
            { <Tag color='green'>인기지수 {record?.popularityIndex}</Tag> }
            { record?.user?.isBanned && <Tag color='red'>정지회원</Tag> }
          </>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>닉네임</Title>
            <Link to={`/users/show/${record?.user?.id}`}>
              {
                record && record.user &&
                <Avatar
                  size={32}
                  src={avatarUrl(record?.user?.avatar)}
                  alt={record?.user?.realname}
                />
              }
              <Text style={{marginLeft: 8}}>{record?.user?.username ?? 'n/a'}</Text>
            </Link>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일</Title>
            <Text style={ record?.user?.isActive ? { color: "green"} : {}}>{record?.user?.email}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>실명</Title>
            {record?.user?.realname ? <Text>{record?.user?.realname}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전화</Title>
            {
              record && record.user && <Text>{phoneNumber(record?.user?.phone)}</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>나이</Title>
            {record?.user?.gender ? (record?.user?.gender === 'F' ? <Text>여자</Text> : <Text>남자</Text>)  : ''}
            {age ? <Text>{`만${age}세`}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>권한</Title>
            {record?.user?.role !== 'USER' ? <Tag color='indigo'>{record?.user?.role}</Tag> : <Tag>{record?.user?.role}</Tag>}
          </Col>
        </Row>
      </Card>
      <Card type="inner" title="셀러 정보">
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>셀러타입</Title>
            <Text style={{marginRight: 8}}>{sellerTypeName(record?.sellerType)}</Text>
            <Tag color={record?.isApproved ? 'green' : 'red'}>{record?.isApproved ? '인증' : '미인증'}</Tag>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>작가명</Title>
            <Text>{record?.name ?? ''}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>장르</Title>
            {
              record?.genre && <Text style={{fontWeight: `bold`, color: `green`}}>#{genreName(record?.genre)}</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>해쉬택1</Title>
            {
              record?.hashtag1 && <Text style={{fontWeight: `bold`, color: `green`}}>#{record?.hashtag1}</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>해쉬택2</Title>
            {
              record?.hashtag2 && <Text style={{fontWeight: `bold`, color: `green`}}>#{record?.hashtag2}</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>국적</Title>
            <Text>{countryName(record?.nationality)}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>작가SNS</Title>
            {
              record?.sns ? <Text>{record?.sns}</Text> : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>작가제공 연락처</Title>
            {
              record?.contact ? <Text>{record?.contact}</Text> : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>생성일</Title>
            {
              record?.createdAt
              ? <ReactTimeAgo date={Date.parse(record?.createdAt!)} locale="ko" />
              : <Tag>xx</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최근수정일</Title>
            {
              record?.updatedAt
              ? <ReactTimeAgo date={Date.parse(record?.updatedAt!)} locale="ko" />
              : <Tag>xx</Tag>
            }
          </Col>
          {/* <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>은행명</Title>
            {
              record?.bank ? <Text>{bankName(record?.bank)}</Text> : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>예금주</Title>
            {
              record?.accountName ? <Text>{record?.accountName}</Text> : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>계좌번호</Title>
            {
              record?.accountNumber ? <Text>{record?.accountNumber}</Text> : <Tag>기록없음</Tag>
            }
          </Col> */}
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>대표이미지</Title>
            {
              record?.images ? <Image.PreviewGroup key="images">{
                record?.images.map(function (v, idx) {
                  return <span key={`image-span-${idx}`} style={{ marginRight: '10px'}}><Image
                    key={`image-${idx}`}
                    width={90}
                    src={v}
                    alt={`image #${idx}`}
                    style={{ border: '1px solid #888' }}
                  />
                </span>;
              })}</Image.PreviewGroup> : <Tag>자료없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>작가소개</Title>
            {
              record?.intro
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.intro} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>작가약력 개인전 (credentials)</Title>
            {
              record?.credentials
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><ReactMarkdown>{record?.credentials}</ReactMarkdown></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>작가약력 단체전 (groups)</Title>
            {
              record?.groups
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><ReactMarkdown>{record?.groups}</ReactMarkdown></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>작가약력 기타 (others)</Title>
            {
              record?.others
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><ReactMarkdown>{record?.others}</ReactMarkdown></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>비고</Title>
            {
              record?.note ? <div>{record?.note}</div> : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
      </Card>
      <Card type="inner" title={record?.sellerType === 'RESELLER' ? '리셀러 작품정보' : '아티스트 작품정보'} style={{marginBottom: 24}}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={4}><Title level={5} style={{fontSize: 11}}>작품</Title></Col>
              <Col span={6}><Title level={5} style={{fontSize: 11}}>작품명</Title></Col>
              <Col span={4}><Title level={5} style={{fontSize: 11}}>구분</Title></Col>
              <Col span={4}><Title level={5} style={{fontSize: 11}}>추정가</Title></Col>
              <Col span={6}><Title level={5} style={{fontSize: 11}}>사이즈</Title></Col>
            </Row>
          {
            artworks.length > 0
            ? artworks!.map((artwork: IArtwork, i: number) => {
              return <Row key={`artwork-row-${i}`} gutter={[16, 16]}>
                <Col key={`artwork-col1-${i}`} span={4}>
                  <Link to={`/artworks/show/${artwork?.id}`}>
                    <Avatar
                      size={64}
                      shape="square"
                      src={firstImageUrl(artwork?.images)}
                      alt={artwork?.title}
                    />
                  </Link>
                </Col>
                <Col key={`artwork-col2-${i}`} span={6}>
                  <div><Text ellipsis>{artwork?.title}</Text></div>
                </Col>
                <Col key={`artwork-col4-${i}`} span={4}>
                  <Tag>{categoryName(artwork.category)}</Tag>
                </Col>
                <Col key={`artwork-col3-${i}`} span={4}>
                  <Text>{artwork?.estimate!.toLocaleString()}원</Text>
                </Col>
                <Col key={`artwork-col5-${i}`} span={6}>
                  <Tag>{artwork?.size}</Tag>
                  <Text>{(artwork?.width/10).toFixed(1)}cm x {(artwork?.height/10).toFixed(1)}cm</Text>

                </Col>
              </Row>
            })
            : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
          }
          </Col>
        </Row>
      </Card>
    </Show>
  );
};
