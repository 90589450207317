import {
  Card,
  Col,
  Form,
  FormProps, Input,
  InputNumber,
  message,
  Modal,
  ModalProps,
  Radio,
  Row
} from "@pankod/refine-antd";

type PaymentModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  orderId: number;
};

export const PaymentModal: React.FC<PaymentModalProps> = ({
  modalProps,
  formProps,
  orderId,
}) => {
  return (
    <Modal {...modalProps} title="수동 결제정보 생성">
      <Form
        { ...formProps }
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values,
            orderIds: [orderId],
            isAdmin: true,
          });
        }}
        layout="vertical"
      >
        <Card
          type="inner"
          title="구매자 정보"
          extra="모든내용을 정확히 입력하세요."
        >
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label="판매가"
                name="priceSubtotal"
                required={true}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="배송료"
                name="shippingSubtotal"
                required={true}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="우편번호"
                name="postalCode"
                required={true}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="주소"
                name="shippingAddress"
                required={true}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Modal>
  );
};
