import React from "react";
import { Bar } from "react-chartjs-2";

interface HistogramChartProps {
  data: number[];
  type: string;
}

interface HistogramData {
  labels: string[];
  datasets: any[];
}

const generateHistogramData = (data: number[], binSize: number): HistogramData => {
  const min = Math.min(...data) ?? 0;
  const max = Math.max(...data) ?? 0;
  const bins = Math.ceil((max - min + 1) / binSize);

  console.log(`min, max, bins: `, min, max, bins);
  
  const items = Array(bins).fill(0);
  data.forEach(value => {
    const bin = binSize ? Math.floor((value - min) / binSize) : 99;
    items[bin]++;
  });
  
  const labels = Array.from({ length: bins }, (v, i) => `${min + i * binSize} - ${min + (i + 1) * binSize - 1}`);

  return {
    labels,
    datasets: [{
      label: '작가 지수 분포',
      data: items,
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }]
  };
};

export const HistogramChart: React.FC<HistogramChartProps> = ({ data, type }: HistogramChartProps) => {
  const binSize = type === `fleaauction`
    ? 40
    : type === `popularity`
      ? 9000 : 100; // 구간 크기 설정
  const histogramData = generateHistogramData(data, binSize);

  const dataset = histogramData.datasets[0].data;
  const total = dataset.reduce((a: number, c: number) => a + c, 0);
  const options = {
    responsive: true,
    plugins: {
      labels: {
        render: (args: any) => {
          const v = args.value;
          const percentage = v/total * 100;
          return `${percentage.toFixed(1)}%`;
        }
      }
    },
  };

  return <Bar options={options} data={histogramData}  />;
};