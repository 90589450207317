import {
  Card,
  Col,
  Create,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { IBanner } from "interfaces";

export const BannerCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<IBanner>();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} layout="vertical">
        <Card
          type="inner"
          title="배너 생성"
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label='배너 타입'
                name='bannerType'
                initialValue='popup'
                rules={[{required: true}]}
              >
                <Select
                  options={[
                    { label: 'POPUP (팝업배너)', value: 'popup' }, 
                    { label: 'HERO (가로형)', value: 'hero' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="배너명"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="이동 타겟"
                name="target"
                initialValue={null}
                rules={[{required: false}]}
              >
                <Select
                  options={[
                    { label: '이동 없음', value: null, },
                    { label: '기획전', value: 'packs', },
                    { label: '옥션', value: 'auctions', },
                    { label: '아티클', value: 'articles', },
                    { label: '이벤트', value: 'events', },
                    { label: '작가/리셀러', value: 'artists', },
                    { label: '사용자', value: 'users', },
                    { label: '마이페이지', value: 'mypage', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="이동 타겟 아이디"
                name="targetId"
                rules={[{required: false}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="활성상태"
                name="isActive"
                initialValue={false}
              >
                <Radio.Group
                  options={[
                    { label: '활성', value: true, },
                    { label: '비활성', value: false, },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  );
};
