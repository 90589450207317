import {
  Avatar,
  Button,
  Card,
  Col,
  Image, Row,
  Show,
  Space,
  Tag,
  Typography
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useDelete, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dayjs from "dayjs";
import { IBanner, IGrant } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const BannerShow: React.FC<IResourceComponentsProps> = () => {
  // useShow
  const { queryResult } = useShow<IBanner>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  // useDelete
  const { mutate } = useDelete<IGrant>();
  const deleteGrant = (id: number) => {
    mutate(
      {
        resource: `banners/${record?.id}/users`,
        id: id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  // useEffect(() => {
  //   console.log(`record has been changed; ${record}`)
  //   setGrants(record?.grants)
  // }, [record]);

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card type="inner" title="배너 정보">
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>배너 종류</Title>
            <>
            {
              record?.bannerType === 'hero'
                ? <Tag color='indigo'>HERO</Tag>
                : <Tag color='teal'>POPUP</Tag>
            }
            <Text>{ record?.title }</Text>
            </>
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>이동 타겟</Title>
              {record?.target ? <Text strong>{record?.target}</Text> : <Tag color='red'>이동 없음</Tag>}
              {record?.target && record?.targetId ? <Text style={{marginLeft: 8}}>{`#${record?.targetId}`}</Text> : ``}
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>상태</Title>
            {
              record?.isActive ? <Tag color="green">활성</Tag> : <Tag>비활성</Tag>
            }
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>시작/종료</Title>
            <div>
              <div>
                {
                record?.startedAt &&
                  <>
                    <Tag>시작</Tag>
                    <ReactTimeAgo date={Date.parse(record?.startedAt)} locale="ko" />
                    {' '}
                    <Text>{dayjs(record.startedAt).format('M월D일 HH시MM분')}</Text>
                  </>
                }
              </div>
              <div>
                {
                record?.endedAt &&
                  <>
                    <Tag>종료</Tag>
                    <ReactTimeAgo date={Date.parse(record?.endedAt)} locale="ko" />
                    {' '}
                    <Text>{dayjs(record.endedAt).format('M월D일 HH시MM분')}</Text>
                  </>
                }
              </div>
            </div>
          </Col>
          {
            record?.bannerType !== 'popup' ?
            <>
              <Col span={6}>
                <Title level={5} style={{fontSize: 11}}>PC 이미지 preview</Title>
                  {
                  record?.image
                    ? <Image
                        src={ record?.image}
                        width={240}
                        height={90}
                      />
                    : <Card
                        style={{
                          width: 240,
                          height: 90,
                          borderRadius: 0,
                          backgroundColor: 'grey',
                        }}
                      >No image</Card>
                  }
              </Col>
              <Col span={6}>
                <Title level={5} style={{fontSize: 11}}>모바일 이미지 preview</Title>
                  {
                  record?.mobileImage
                    ? <Image
                        src={ record?.mobileImage}
                        width={90}
                        height={90}
                      />
                    : <Card
                        style={{
                          width: 90,
                          height: 90,
                          borderRadius: 0,
                          backgroundColor: 'grey',
                        }}
                      >No image</Card>
                  }
              </Col>
              <Col span={6}>
                <Title level={5} style={{fontSize: 11}}>배경색 preview</Title>
                  {
                  record?.color
                    ? <Card
                        style={{
                          width: 90,
                          height: 90,
                          borderRadius: 0,
                          backgroundColor: record?.color,
                        }}
                      >{record?.color}</Card>
                    : <Card
                        style={{
                          width: 90,
                          height: 90,
                          borderRadius: 0,
                          backgroundColor: 'grey',
                        }}
                      >No color</Card>
                  }
              </Col>
            </>
            :
            <>
              <Col span={6}>
                <Title level={5} style={{fontSize: 11}}>버튼 preview</Title>
                {
                  record?.buttonType === 'info' &&
                  <Button style={{
                    'backgroundColor' : '#6ad1c3',
                    'color': 'black',
                  }}>{record?.buttonLabel}</Button>
                }
                {
                  record?.buttonType === 'warn' &&
                  <Button style={{
                    'backgroundColor' : 'yellow',
                    'color': 'black',
                  }}>{record?.buttonLabel}</Button>
                }
                {
                  record?.buttonType === 'error' &&
                  <Button style={{
                    'backgroundColor' : 'red',
                    'color': 'white',
                  }}>{record?.buttonLabel}</Button>
                }
                {
                  record?.buttonType === 'success' &&
                  <Button style={{
                    'backgroundColor' : 'green',
                    'color': 'white',
                  }}>{record?.buttonLabel}</Button>
                }
              </Col>
            </>
          }          
        </Row>
      </Card>
      </Show>
  )
};
