import { EyeOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  getValueFromEvent,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import dayjs from "dayjs";
import { IAdminUser } from "interfaces";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ACCESS_TOKEN } from "../../constants";

const { Text } = Typography;

// options
const radioOptions = [
  { label: 'ON', value: true },
  { label: 'OFF', value: false }, 
];
const reverseRadioOptions = [
  { label: 'ON', value: false },
  { label: 'OFF', value: true }, 
];

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, edit, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useUpdate hook
  const { mutate: mutateUser } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IAdminUser>({
    redirect: false,
  });

  // useState() hooks
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isArtist, setIsArtist] = useState(false);
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");
  const [uri, setUri] = useState('');

  // more constants
  const record = queryResult?.data?.data as IAdminUser;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  //?-------------------------------------------------------------------------//
  //? file upload
  //?-------------------------------------------------------------------------//

  const uploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: fileList,
    action: `${apiUrl}/users/${record?.id}/avatar`,
    headers: headers,
    async onChange({ file, fileList: newFileList }) {
      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            // you can skip the type here
            url: file.response?.avatar,
          }];
        }

        form.setFieldsValue({ avatar: file.response?.avatar });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        inputData.avatar = images ? images[0] : null;
        mutateUser({
          resource: `users`, values: inputData, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "users", id: record.id, invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`닉네임: ${record?.username}`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ avatar: null });
          setFileList([])
        } catch (e) {
          console.error('removing an image from S3 failed.')
        }
      }
    }
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  // handlers : toggling artist input box
  const handleClickSwitch = (val: boolean) => setIsArtist(val);

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('users-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    // console.log(`[useEffect hook] <IAdminUser> data has been changed. => ${record}`)
    // if it has artist
    if (record?.artist) {
      console.log("set isArtist to true");
      setIsArtist(true)
    }
    // if it has avatar
    if (record?.avatar) {
      const url = record?.avatar;
      if (! url.includes('user.png')) {
        const filename = url.replace(/^.*[\\/]/, '');
        const extention = url.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
        setFileList([{
          uid: '-1',
          name: filename,
          status: 'done',
          type: `image/${extention}`,
          url: url,
        }])
      }
    }
  }, [record]);

  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<EyeOutlined />}
            onClick={(): void => goto("users", record?.id!)}
          >Show</Button>
          {defaultButtons}
        </>
      )}
    >
      <Form
        {...formProps}
        form={form}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values, isArtist: isArtist,
          });
          // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
          setTimeout(() => {
            if (uri.length > 0) {
              push(`../users?${uri}`);
            } else {
              goBack();
            }
          }, 500)
        }} 
        layout="vertical"
      >
        <Card type="inner" title="기본정보" style={{marginBottom: 24}}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="이메일확인"
                name="isActive"
              >
                <Radio.Group
                  options={radioOptions}
                  value={record?.isActive}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="프로필공개"
                name="isAnonymous"
              >
                <Radio.Group
                  options={reverseRadioOptions}
                  value={record?.isAnonymous}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="낙찰내역공개"
                name="isPrivate"
              >
                <Radio.Group
                  options={reverseRadioOptions}
                  value={record?.isPrivate}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="사용정지"
                name="isBanned"
              >
                <Radio.Group
                  options={radioOptions}
                  value={record?.isBanned}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item label="사진 업로드">
                <Form.Item
                  name="avatar"
                  valuePropName="[fileList]"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                <ImgCrop rotate>
                  <Upload { ...uploadProps }>
                    {fileList.length < 1 && '+ Upload'}
                  </Upload>
                </ImgCrop>
                <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handlePreviewModalClose}>
                  <img alt="avatar" style={{ width: '100%' }} src={previewImage} />
                </Modal>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="자격"
                name="role"
              >
                <Radio.Group
                  options={[
                    { label: "USER", value: "USER", },
                    { label: "ADMIN", value: "ADMIN", },
                    { label: "SUPER", value: "SUPER", },
                  ]}
                  value={record?.role}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="닉네임"
                name="username"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="전화번호"
                name="phone"
              >
                <Input placeholder='숫자만 입력하세요!' />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="이메일"
                name="email"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="비밀번호"
                name="password"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="실명"
                name="realname"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="성별"
                name="gender"
              >
                <Select
                  options={[
                    { label: "여자", value: "F", },
                    { label: "남자", value: "M", },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="언어"
                name="locale"
              >
                <Select
                  options={[
                    { label: "한국어", value: "ko", },
                    { label: "영어", value: "en", },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="생년월일"
                name="dob"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
              >
                <DatePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="가입일"
                name="createdAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
              >
                <DatePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="최근사용일"
                name="updatedAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
              >
                <DatePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="탈퇴일"
                name="deletedAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
              >
                <DatePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="푸시토큰"
                name="pushToken"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card type="inner" title="프로필정보" style={{marginBottom: 24}}>
          <Form.Item name="profileId" initialValue={record?.profile?.id} hidden>
            <Input />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="구매횟수"
                name="profile.payCount"
                initialValue={record?.profile?.payCount}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="조회수"
                name="profile.viewCount"
                initialValue={record?.profile?.viewCount}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
            </Col>
            <Col span={6}>
              <Form.Item
                label="푸시알림"
                name="profile.notifyPush"
                initialValue={record?.profile?.notifyPush}
                valuePropName="checked"
              >
                <Checkbox >푸시알림</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="카카오알림"
                name="profile.notifyKakao"
                initialValue={record?.profile?.notifyKakao}
                valuePropName="checked"
              >
                <Checkbox >카카오알림</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="이메일알림"
                name="profile.notifyEmail"
                initialValue={record?.profile?.notifyEmail}
                valuePropName="checked"
              >
                <Checkbox>이메일알림</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="이벤트알림"
                name="profile.notifyEvent"
                initialValue={record?.profile?.notifyEvent}
                valuePropName="checked"
              >
                <Checkbox>이벤트알림</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="소개글"
                name="profile.bio"
                initialValue={record?.profile?.bio ?? ''}
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          type="inner"
          title="셀러정보"
          extra={<Switch checked={isArtist} onClick={handleClickSwitch} />}
        >
          <Form.Item name="artistId" hidden initialValue={record?.artist?.id}><Input /></Form.Item>
          {!isArtist && (
          <Row gutter={[16, 16]}>
            <Col span={24}>셀러정보가 없는 일반사용자입니다.</Col>
          </Row>)}
          {isArtist && (
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="작가명/리셀러명"
                name="artist.name"
                initialValue={record?.artist?.name}
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="장르"
                name="artist.genre"
                initialValue={record?.artist?.genre ?? 'ETC'}
              >
                <Select
                  options={[
                    { label: '🎨 원화작가', value: 'PAINTER', },
                    { label: '✍🏼 일러스트레이터', value: 'DRAWER', },
                    { label: '🪆 조각가', value: 'SCULPTOR', },
                    { label: '📸 사진작가', value: 'PHOTOGRAPHER', },
                    { label: '📺 비쥬얼아티스트', value: 'VISUAL_ARTIST', },
                    { label: '🖼️ 갤러리', value: 'GALLERY', },
                    { label: '기타', value: 'ETC', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="국적"
                name="artist.nationality"
                initialValue={record?.artist?.nationality ?? 'kr'}
              >
                <Select
                  options={[
                    { label: '🇰🇷 한국', value: 'kr', },
                    { label: '🇬🇷 그리스', value: 'gr', },
                    { label: '🇿🇦 남아공', value: 'za', },
                    { label: '🇳🇴 노르웨이', value: 'no', },
                    { label: '🇳🇱 네덜란드', value: 'nl', },
                    { label: '🇳🇿 뉴질랜드', value: 'nz', },
                    { label: '🇩🇪 독일', value: 'de', },
                    { label: '🇹🇼 대만', value: 'tw', },
                    { label: '🇩🇰 덴마크', value: 'dk', },
                    { label: '🇲🇾 말레이시아', value: 'my', },
                    { label: '🇲🇽 멕시코', value: 'mx', },
                    { label: '🇺🇸 미국', value: 'us', },
                    { label: '🇧🇪 벨기에', value: 'be', },
                    { label: '🇧🇬 불가리아', value: 'bg', },
                    { label: '🇧🇷 브라질', value: 'br', },
                    { label: '🇨🇭 스위스', value: 'ch', },
                    { label: '🇸🇪 스웨덴', value: 'se', },
                    { label: '🇪🇸 스페인', value: 'es', },
                    { label: '🇸🇬 싱가폴', value: 'sg', },
                    { label: '🇮🇪 아일랜드', value: 'ie', },
                    { label: '🇦🇷 아르헨티나', value: 'ar', },
                    { label: '🇬🇧 영국', value: 'gb', },
                    { label: '🇺🇦 우크라이나', value: 'ua', },
                    { label: '🇮🇷 이란', value: 'ir', },
                    { label: '🇮🇶 이라크', value: 'iq', },
                    { label: '🇪🇬 이집트', value: 'eg', },
                    { label: '🇮🇹 이탈리아', value: 'it', },
                    { label: '🇮🇳 인도', value: 'in', },
                    { label: '🇮🇩 인도네시아', value: 'id', },
                    { label: '🇯🇵 일본', value: 'jp', },
                    { label: '🇯🇲 자메이카', value: 'jm', },
                    { label: '🇨🇳 중국', value: 'cn', },
                    { label: '🇨🇦 캐나다', value: 'ca', },
                    { label: '🇹🇭 태국', value: 'th', },
                    { label: '🇹🇷 튀르키예(터키)', value: 'tr', },
                    { label: '🇵🇱 폴란드', value: 'pl', },
                    { label: '🇫🇷 프랑스', value: 'fr', },
                    { label: '🇫🇮 핀란드', value: 'fi', },
                    { label: '🇵🇭 필리핀', value: 'ph', },
                    { label: '🇭🇺 헝가리', value: 'hu', },
                    { label: '🇦🇺 호주', value: 'au', },
                    { label: '기타', value: 'other', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="셀러타입"
                name="artist.sellerType"
                initialValue={record?.artist?.sellerType ?? 'ARTIST3'}
              >
                <Select
                  options={[
                    { label: '🟪 플리크루', value: 'ARTIST1', },
                    { label: '🟧 작가', value: 'ARTIST2', },
                    { label: '🟥 예비작가', value: 'ARTIST3', },
                    { label: '🟩 리셀러', value: 'RESELLER', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
            {
              record?.artist &&
              <Button
                onClick={(): void => edit("artists", record?.artist?.id!)}
                style={{marginRight: 10}}
              >
              셀러수정
              </Button>

            }
              <Text>작가명을 입력후 Save 버튼을 누르면, 셀러정보가 생성됩니다.</Text>
            </Col>
          </Row>
          )}
        </Card>
      </Form>
    </Edit>
  );
};
