import {
  Form, FormProps, InputNumber, Modal, ModalProps
} from "@pankod/refine-antd";

type CreateForceOrderModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreateForceOrderModal: React.FC<CreateForceOrderModalProps> = ({
  modalProps,
  formProps,
}) => {
  return (
    <Modal {...modalProps} title="사용자 지정 Order 생성" width={240}>
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values, isAdmin: true
          });
        }}    
        layout="vertical"
      >
        <Form.Item label="사용자ID (숫자)" name="userId">
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="금액 (숫자)" name="amount">
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => `${value}`.replace(/[,]+/g, '')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
