import { Avatar, Col, Row, Space, Table, Tabs, Tag, Typography } from "@pankod/refine-antd";
import {
  useGetIdentity,
  useOne,
} from "@pankod/refine-core";
import { RefineErrorPageProps } from "@pankod/refine-ui-types";
import { IArtist, IUserStats } from "interfaces";
import React, { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import 'chartjs-plugin-labels';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../constants';
import { AgeFromDateString } from "age-calculator";
import { genreName } from "helpers/artwork";
import routerProvider from "@pankod/refine-react-router-v6";
import { HistogramChart } from "pages/dashboard/components/histogram-chart";
import { RegistrationChart } from "pages/dashboard/components/registration-chart";

const { Link } = routerProvider;
const { Title, Text } = Typography;

const withdrawalOptions = {
  responsive: true,
  plugins: {
    labels: { render: 'label' },
    position: 'top' as const,
  }
};

const reasons = [
  '중복 계정이 있어요.',
  '앱 오류가 많아요.',
  '자주 사용하지 않아요.',
  '알람이 너무 자주 와요.',
  '개인정보 노출이 걱정돼요.',
  '휴대폰 번호를 바꿀 예정이에요.',
  '기타.'
];

const columns = [
  {
    title: '순위',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: '작가명',
    dataIndex: 'name',
    key: 'name',
    render: (_: any, v: any) => (
      <Link to={`/artists/show/${v.artistId}`}>{v.name}</Link>
    ),
  },
  {
    title: '사용자명',
    dataIndex: 'username',
    key: 'username',
    render: (_: any, v: any) => (
      <Link to={`/users/show/${v.userId}`}>
        <Avatar
          size={32}
          src={v.avatar}
          alt={v.username}
        />
        <span style={{ marginLeft: 8 }}>{v.username}</span>
      </Link>
    ),
  },
  {
    title: '장르',
    dataIndex: 'genre',
    key: 'genre',
    render: (v: string) => <>{genreName(v)}</>
  },
  {
    title: '해쉬택',
    dataIndex: 'hashtag1',
    key: 'hashtag1',
  },
  {
    title: '해쉬택',
    dataIndex: 'hashtag2',
    key: 'hashtag2',
  },
  {
    title: '성별',
    dataIndex: 'gender',
    key: 'gender',
    render: (v: string | null) => <>{
        v === 'M'
        ? <Tag color='blue'>남자</Tag>
        : v === 'F'
          ? <Tag color='pink'>여자</Tag> : 'n/a'
    }</>
  },
  {
    title: '나이',
    dataIndex: 'dob',
    key: 'dob',
    render: (v: string | null) => <>{
      v ? <>{new AgeFromDateString(v).age}</> : 'n/a'
    }</>
  },
  {
    title: '점수',
    dataIndex: 'score',
    key: 'score',
  },
];

export const DashboardPage: React.FC<RefineErrorPageProps> = () => {

  const [registrationData, setRegistrationData] = useState<any>(null);
  const [withdrawalData, setWithdrawalData] = useState<any>(null);
  const [others, setOthers] = useState<string[]>([]); // other reason to withdraw
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [rankIndex, setRankIndex] = useState<string>('fleaauction');

  const { data: userIdentity } = useGetIdentity();

  const { data: rankData, refetch } = useOne<IArtist[]>({
    resource: `artists/ranks`,
    id: rankIndex,
    queryOptions: {
      // enabled: true,
      onSuccess: (data) => {
        const items = data?.data as IArtist[];
        const ranks = items.map((v, i) => {
          return {
            key: i+1,
            artistId: v.id,
            name: v.name,
            userId: v.user?.id,
            username: v.user?.username,
            avatar: v.user?.avatar,
            dob: v.user?.dob,
            gender: v.user?.gender,
            genre: v.genre,
            hashtag1: v.hashtag1,
            hashtag2: v.hashtag2,
            score: rankIndex === 'fleaauction'
              ? v.fleaauctionIndex
              : rankIndex === 'background'
                ? v.backgroundIndex
                : v.popularityIndex,
          };
        })
        setDataSource(ranks);
      },
    }
  });

  const { data: regiData } = useOne<IUserStats[]>({
    resource: `users/stats`,
    id: 1,
    queryOptions: {
      // enabled: true,
      onSuccess: (data) => {
        const items = ((data?.data as any)?.data as IUserStats[]);
        setRegistrationData({
          labels: items.map((v: IUserStats) => v.date),
          datasets: [{
            label: '가입자 수',
            data: items.map((v) => v.total),
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          }]
        });
      },
    }
  });

  const { data: quitData } = useOne<IUserStats[]>({
    resource: `users/stats`,
    id: `quit`,
    queryOptions: {
      // enabled: true,
      onSuccess: (data) => {
        let counts = [0, 0, 0, 0, 0, 0, 0];
        const items = ((data?.data as any)?.data as string[]);
        const etc = items.map((v) => {
          switch (v) {
            case reasons[0]:
              counts[0]++;
              break;
            case reasons[1]:
              counts[1]++;
              break;
            case reasons[2]:
              counts[2]++;
              break;
            case reasons[3]:
              counts[3]++;
              break;
            case reasons[4]:
              counts[4]++;
              break;
            case reasons[5]:
              counts[5]++;
              break;
            default:
              counts[6]++;
              return v;
          }
          return null;
        });
        setOthers(etc.filter((v) => v) as string[]);
        setWithdrawalData({
          labels: reasons,
          datasets: [{
            label: '누적 횟수',
            data: counts,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(128, 128, 128, 0.2)',
            ],
            borderWidth: 1,
          }]
        });
      },
    }
  });

  const onChangeTab = (key: string) => {
    setRankIndex(key)
    refetch();
  };

  useEffect(() => {
    if (userIdentity && userIdentity.role === 'USER') {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
  }, [userIdentity]);

  return (
    userIdentity?.role !== 'USER'
    ? <>
        <Row>
          <Col span={24}><Title level={5}>지난 30일간 가입자 수 변화 추이</Title></Col>
          <Col span={24} style={{marginBottom: 16}}>
            { registrationData && <RegistrationChart data={registrationData} /> }
          </Col>
          <Col span={24}><Title level={5}>플리 TOP 50 작가</Title></Col>
          <Col span={24}>
            <Tabs
              defaultActiveKey="1"
              onChange={onChangeTab}
            >
              <Tabs.TabPane tab="참여지수" key="fleaauction"></Tabs.TabPane>
              <Tabs.TabPane tab="이력지수" key="background"></Tabs.TabPane>
              <Tabs.TabPane tab="인기지수" key="popularity"></Tabs.TabPane>
            </Tabs>
            {
              dataSource.length > 0 && <HistogramChart data={dataSource.map((v) => v.score)} type={rankIndex} />
            }
            <Table columns={columns} dataSource={dataSource} size={`small`} pagination={{ pageSize: 50 }} />
          </Col>

          <Col span={24}><Title level={5}>탈퇴사유 누적 분포</Title></Col>
          <Col span={24}>{ withdrawalData ? <Doughnut options={withdrawalOptions} data={withdrawalData} /> : <></>}</Col>
          <Col span={24}><Tag style={{marginRight: 8}}>기타 사유</Tag><Text style={{fontSize: 11}}>{others.join(', ')}</Text></Col>
        </Row>
        <br />
      </>
    : <Space direction="horizontal" style={{
        width: '100%',
        justifyContent: 'center'
      }}>
        <div style={{ width: 240 }}>
          <img src="https://cdn.fleaauction.world/svgs/robot.svg" alt="Robot" />
        </div>      
        <h1>STOP, RIGHT THERE! YOU LITTLE SNEAK.</h1>
        <h1>YOU ARE NOT ALLOWED TO ACCESS THIS.</h1>
      </Space>);
};
