import {
  Avatar,
  Button,
  DatePicker,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  message,
  NumberField,
  ShowButton,
  Space,
  Table, TagField, TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { IEvent } from "interfaces";
import ReactTimeAgo from "react-time-ago";
import { CSVLink } from 'react-csv';
import { useState } from "react";
import { ACCESS_TOKEN } from "../../constants";
import axios from 'axios';

const { Link } = routerProvider;
const { RangePicker } = DatePicker;

export const EventList: React.FC<IResourceComponentsProps> = () => {
  const now = (new Date()).getTime();
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [csvHeaders, setCsvHeaders] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);

  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  const { tableProps, sorter } = useTable<IEvent>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  const loadQrData = async (from: string, to: string) => {
    if (!from || !to) {
      message.warn(`생성할 날짜 범위를 지정해주세요.`)
    }
    try {
      axios({
        method: 'get',
        url: `${apiUrl}/qrs/report?start=${from}&end=${to}`,
        headers: headers,
      }).then((data) => {
        if (data.data.slugs.length > 0 && data.data.items.length > 0) {
          message.info(`CSV 데이터 ${data.data.items.length} 건이 검색되었습니다. 다운로드 하세요.`)
          setCsvHeaders([
            { label: '날짜', key: "date", },
            { label: 'QR종류 갯수', key: "count", },
            { label: 'QR카운트 합산', key: "total", },
            ...data.data.slugs.map((slug: string) => {
              return { label: slug, key: slug };
            }),
          ]);
          setCsvData(data.data.items);
        } else {
          message.info(`해당 기간의 데이터가 없습니다.`)
        }
      })
    } catch (e) {
      message.error('데이터 조회 오류')
    }
  }

  const handleRangeChange = async (value: any) => {
    const [fromVal, toVal] = value;
    const fromDate = fromVal.toISOString().split('T')[0];
    const toDate = toVal.toISOString().split('T')[0];
    setFrom(fromDate);
    setTo(toDate);
    await loadQrData(fromDate, toDate);
  }

  return (
    <List
      headerButtons={() => (
        <>
        <RangePicker
          onChange={handleRangeChange}
        />
        {
          csvData.length > 0 && 
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={`events_${from}_${to}.csv`}
          ><Button>다운로드</Button>
          </CSVLink>
        }
        </>
      )}    
    >
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={(tableProps?.dataSource as any)?.data}
      >
        <Table.Column<IEvent>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IEvent>
          dataIndex="name"
          key="name"
          title="이벤트 제목"
          render={(_, record) => (
            <div>
              <div>
                <TextField value={`${record.title}`} />
              </div>          
              <Link to={`/events/show/${record?.id}`}>
                <Space>
                {
                  record?.image
                    ? <Avatar size={64} shape="square" src={record?.image} alt={record?.title} />
                    : <Avatar size={64} shape="square" alt={record?.title} />
                }
                {/* {
                  record?.mobileImage
                    ? <Avatar size={64} shape="square" src={record?.mobileImage} alt={record?.title} />
                    : <Avatar size={64} shape="square" alt={record?.title} />
                } */}
                </Space>
              </Link>
            </div>
          )}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IEvent>
          dataIndex="buttons"
          key="buttons"
          title="버튼 갯수"
          render={(_, record) => 
            <>
            <TextField value={record.buttons ? record.buttons.length : 0} />            
            </>
          }
        />
        <Table.Column<IEvent>
          dataIndex="isActive"
          key="isActive"
          title="노출"
          render={(_, record) => 
            <>
            {
              record?.isActive ? <TagField
              color='green'
              value='활성'
            /> : <TagField value='비활성' />
            }
            </>
          }
        />
        <Table.Column<IEvent>
          dataIndex="createdAt"
          key="createdAt"
          title="생성일"
          render={(value) => {
            return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<IEvent>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
