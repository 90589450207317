import {
  AutoComplete,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  message,
  Modal,
  ModalProps,
  Row,
  Typography
} from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import { IOptions, IUser } from "interfaces";
import { useEffect, useState } from "react";
const { Text } = Typography;

type CreateGrantUserProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

const renderTitle = (title: string) => {
  return (
    <Text strong style={{ fontSize: "16px" }}>
      {title}
    </Text>
  );
};

const renderItem = (name: string, resource: string, id: number) => {
  return {
    key: id,
    value: name,
    label: (<Text>{ name }</Text>),
  };
};

export const CreateGrantUser: React.FC<CreateGrantUserProps> = ({
  modalProps,
  formProps,
}) => {
  const [username, setUsername] = useState<string>('');
  const [userId, setUserId] = useState<number>(0);
  const [options, setOptions] = useState<IOptions[]>([]);

  const { refetch: refetchUsernames } = useList<IUser>({
    resource: "users.search",
    config: {
      filters: [
        { field: "username", operator: "contains", value: username },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const userOptionGroup = ((data?.data as any)?.data as IUser[]).map((item) =>
          renderItem(
            item.username ?? '',
            "users",
            item.id,
          ),
        );
        if (userOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            //...prevOptions,
            {
              label: renderTitle("닉네임"),
              options: userOptionGroup,
            },
          ]);
        }
      },
    },
  });

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    console.log(`username has been changed; ${username}`)
    setOptions([]);
    refetchUsernames();
  }, [username]);

  return (
    <Modal {...modalProps} title="사용자 선택">
      <Form
        {...formProps}
        onFinish={(values) => {
          const userId = formProps.form?.getFieldValue('userId');
          if (userId) {
            formProps.onFinish && formProps.onFinish({
              ...values,
              userIds: [userId],
            });
          } else {
            message.error(`userId 의 설정이 필요합니다.`)
          }
      }}
      
      layout="vertical">
        <Card
          type="inner"
          title="사용자 정보"
          extra="🔔 쿠폰을 제공한 사용자에게는 푸시메시지가 자동발송 됩니다."
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="사용자 검색"
                name="username"
              >
                <AutoComplete
                  allowClear
                  style={{ width: "100%" }}
                  options={options}
                  filterOption={true}
                  onSearch={(v: string) => setUsername(v)}
                  onSelect={(v: string, o: any) => {
                    setUserId(o.key)
                    formProps.form?.setFieldsValue({ userId: o.key })
                  }}
                  //onSearch={debounce((value: string) => setArtistName(value), 500)}
                >
                  <Input.Search
                    allowClear
                    placeholder="닉네임 검색"
                  />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="사용자ID" name="userId">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Modal>
  );
};
