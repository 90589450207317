import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  Button,
  Card,
  Col,
  Edit,
  Form,
  TextField,
  Input,
  Modal,
  RcFile,
  Row,
  Upload,
  UploadFile,
  UploadProps,
  getValueFromEvent,
  useForm,
  useModalForm,
  Radio
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import { ACCESS_TOKEN } from '../../constants';
import { IEvent, IEventButton } from 'interfaces';
import { useEffect, useState } from "react";
import { AddButtonModal } from 'pages/events/popup/add-button-modal';
import "react-mde/lib/styles/css/react-mde-all.css";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const EventEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useCreate hook
  const { mutate: getSignedUrl } = useCreate();

  // useUpdate hook
  const { mutate: mutateEvent } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<Omit<IEvent, 'id'>>({
    redirect: false,
  });

  // useModalForm hook
  const { formProps: buttonFormProps, modalProps: buttonModalProps, show, close } = useModalForm({
    action: 'create',
  })

  // useState hooks
  const [buttonItems, setButtonItems] = useState<IEventButton[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [fileList, setFileList] = useState<UploadFile[]>([]);  
  // const [mobileFileList, setMobileFileList] = useState<UploadFile[]>([]);

  const [uri, setUri] = useState('');
  const [createObjectUrl, setCreateObjectUrl] = useState('');

  // more constants
  const record = queryResult?.data?.data as IEvent;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  const callback = (i: IEventButton) => {
    setButtonItems([...buttonItems, i]);
    close();
  }

  //?-------------------------------------------------------------------------//
  //? file upload (note that this doesn't make use of S3 direct uploading)
  //?-------------------------------------------------------------------------//

  const uploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: fileList,
    action: `${apiUrl}/events/${record?.id}/image`,
    headers: headers,
    beforeUpload: (file, files) => {
      // setImageFile(file as RcFile);
      if (createObjectUrl) {
        URL.revokeObjectURL(createObjectUrl);
      }
      const url = URL.createObjectURL(file);
      setCreateObjectUrl(url);
      setFileList([{ ...file, url }]);
      // return false;
    },
    onChange({ file, fileList: newFileList }) {
      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            url: file.response?.image, // this would be optional
          }];
        }

        form.setFieldsValue({ image: file.response?.image });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        const values = {
          ...inputData,
          image: images ? images[0] : null,
        }

        mutateEvent({
          resource: `events`, values, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "events", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`이미지: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ image: null });
          setFileList([]);
        } catch (e) {
          console.error('removing from S3 failed.')
        }
      }
    }
  }

  // // this is a quick and dirty solution for mobileImage
  // const mobileUploadProps: UploadProps = {
  //   name: 'file',
  //   listType: 'picture-card',
  //   fileList: mobileFileList,
  //   action: `${apiUrl}/events/${record?.id}/mobile-image`,
  //   headers: headers,
  //   beforeUpload: (file, files) => {
  //     //setMobileImageFile(file as RcFile);
  //     if (createObjectUrl) {
  //       URL.revokeObjectURL(createObjectUrl);
  //     }
  //     const url = URL.createObjectURL(file);
  //     setCreateObjectUrl(url);
  //     setMobileFileList([{ ...file, url }]);
  //     // return false;
  //   },
  //   onChange({ file, fileList: newFileList }) {
  //     if (file.status === 'done') {
  //       if (file.response) {
  //         newFileList = [{
  //           uid: `-1`,
  //           name: file.name,
  //           status: 'done',
  //           url: file.response?.mobileImage, // this would be optional
  //         }];
  //       }

  //       form.setFieldsValue({ mobileImage: file.response?.mobileImage });
  //       const inputData = form.getFieldsValue() as any;
  //       const images = newFileList.map((v) => v.url);
  //       const values = {
  //         ...inputData,
  //         mobileImage: images ? images[0] : null,
  //       }

  //       mutateEvent({
  //         resource: `events`, values, id: record.id,
  //       },
  //       {
  //         onSuccess: (data, variables, context) => {
  //           invalidate({ resource: "events", invalidates: ["detail"] })
  //         },
  //       })
  //     }
  //     setMobileFileList(newFileList);
  //   },
  //   async onPreview(file: UploadFile) {
  //     if (!file.url && !file.preview) {

  //       file.preview = await getBase64(file.originFileObj as RcFile);
  //     }
  //     setPreviewImage(file.url || (file.preview as string));
  //     setPreviewOpen(true);
  //     setPreviewTitle(`이미지: ${record?.title}`);
  //   },
  //   async onRemove(file: UploadFile) {
  //     const imageUrl = file.url as string;
  //     if (imageUrl) {
  //       try {
  //         const input = {
  //           Bucket: `auction-uploads`,
  //           Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
  //         }
  //         const command = new DeleteObjectCommand(input);
  //         await s3Client.send(command);
  //         // update UI
  //         form.setFieldsValue({ mobileImage: null });
  //         setMobileFileList([]);
  //       } catch (e) {
  //         console.error('removing from S3 failed.')
  //       }
  //     }
  //   }
  // }

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('events-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    if (record?.image) {
      const filez = [record?.image].map((v: string) => {
        const filename = v.replace(/^.*[\\/]/, '');
        const extention = v.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
        return {
          uid: -10,
          name: filename,
          status: 'done',
          type: `image/${extention}`,
          url: v,
        } as unknown as UploadFile;
      })
      setFileList(filez);
    }
    // if (record?.mobileImage) {
    //   const filez = [record?.mobileImage].map((v: string) => {
    //     const filename = v.replace(/^.*[\\/]/, '');
    //     const extention = v.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
    //     return {
    //       uid: -20,
    //       name: filename,
    //       status: 'done',
    //       type: `image/${extention}`,
    //       url: v,
    //     } as unknown as UploadFile;
    //   })
    //   setMobileFileList(filez);
    // }
    if (record?.buttons && record?.buttons.length > 0) {
      setButtonItems([...record.buttons])
    }
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button icon={<EyeOutlined />} onClick={(): void => goto("events", record?.id!)}>Show</Button>
          {defaultButtons}
        </>
      )}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form
        form={form}
        {...formProps}
        onValuesChange={(values) => {
          if (values.hasOwnProperty('image')) {
            values['image'] = values['image'] ?? '';
          }
        }}
        onFinish={(values: any) => {
          const vals = {
            ...values,
            image: values['image'] ?? null,
            mobileImage: null, // values['mobileImage'] ?? null,
            buttons: buttonItems,
          };

          console.log('------------------>', vals)
          formProps.onFinish && formProps.onFinish({...vals});
          // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
          setTimeout(() => {
            if (uri.length > 0) {
              push(`../events?${uri}`);
            } else {
              goBack();
            }
          }, 500)
        }}
        layout="vertical"
      >
        <Card type="inner" title="배너 정보">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label="제목"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="부제목"
                name="subtitle"
                rules={[{required: false}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="노출"
                name="isActive"
              >
                <Radio.Group
                  options={[
                    { label: '활성', value: true },
                    { label: '비활성', value: false }, 
                  ]}
                  value={record?.isActive}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="사진 업로드">
                <Form.Item
                  name="image"
                  valuePropName="[fileList]"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                <Upload { ...uploadProps }>
                  {fileList.length < 1 && '+ Upload'}
                </Upload>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <h4>버튼 레이블</h4>
            </Col>
            <Col span={4}>
              <h4>버튼 타입</h4>
            </Col>
            <Col span={4}>
              <h4>버튼 위치</h4>
            </Col>
            <Col span={4}>
              <h4>이동 타겟</h4>
            </Col>
            <Col span={4}>
              <h4>이동 타겟 아이디</h4>
            </Col>
          </Row>
          {
            buttonItems.map((v: IEventButton, i: number) => (
              <Row key={`${v.label}${i}`} gutter={[16, 16]} style={{marginBottom: 8 }}>
                <Col span={8} flex="1" style={{ flex: 1 }}>
                  <DeleteOutlined style={{marginRight: 8, color: '#ff0000'}} onClick={
                    () => {
                      const items = [...buttonItems];
                      console.log(`original `, items);
                      items.splice(i, 1);
                      console.log(`removed `, items);
                      setButtonItems([...items]);
                    }
                  } />
                  <TextField value={v.label} />
                </Col>
                <Col key={`col-2-${i}`} span={4}>
                  <div><TextField value={v.buttonType} /></div>
                </Col>
                <Col key={`col-3-${i}`} span={4}>
                  <div><TextField value={v.location} /></div>
                </Col>
                <Col key={`col-4-${i}`} span={4}>
                  <div><TextField value={v.target} /></div>
                </Col>
                <Col key={`col-5-${i}`} span={4}>
                  <div><TextField value={v.targetId} /></div>
                </Col>
              </Row>
            ))
          }
          <Row gutter={[16, 16]} style={{marginTop: 16}}>
            <Button
              icon={<PlusOutlined />}
              onClick={async () => {
                show();
              }}
            >버튼 추가</Button>
          </Row>
        </Card>
      </Form>
      <AddButtonModal formProps={buttonFormProps} modalProps={buttonModalProps} callback={callback} />
      <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handlePreviewModalClose}>
        <img alt="event" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Edit>
  );
};
