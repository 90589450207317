// import '@atlaskit/css-reset';
import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  MarkdownField,
  Row,
  Show,
  Tag,
  Typography,
  useModal
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow, useUpdate } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dayjs from "dayjs";
import { categoryName, firstImageUrl, orientationImageUrl } from "helpers/artwork";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import { sellerTypeColor, sellerTypeName } from "helpers/seller";
import { avatarUrl } from "helpers/user";
import { IArtwork, IAuction, IHashtag } from "interfaces";
import { EditHashtagsModal } from "pages/artworks/components/hashtags/edit-hashtag-modal";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

interface IImg {
  id: string,
  url: string
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#FEF7E8' : '#F0F2F5',
  display: 'flex',
  padding: 16,
  overflow: 'auto',
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  width: 80,
  margin: `0 16px 0 0`,
  background: isDragging ? '#FEF7E8' : 'transparent',
  ...draggableStyle,
});

export const ArtworkShow: React.FC<IResourceComponentsProps> = () => {
  // useState hook
  const [images, setImages] = useState<Array<IImg>>([]);
  const [active, setActive] = useState<boolean>(false); // button state
  // useShow hook
  const { queryResult } = useShow<IArtwork>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  
  // useModal hook
  const { modalProps, show, close } = useModal();

  // useUpdate hook
  const { mutate: mutateArtwork } = useUpdate();
  const updateArtwork = (id: number) => {
    mutateArtwork({
      resource: "artworks",
      values: {
        images: images.map((v) => v.url)
      },
      id: id,
      
      invalidates: ['detail']
    },
    {
      onSuccess: (data, variables, context) => {
        setActive(false);
      }
    });
  }

  // event handlers
  const handleChange = (result: any) => {
    if (!result.destination) return;

    const items = [...images];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setActive(true);
    setImages(items);
  };

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//
  
  useEffect(() => {
    if (record?.images && record?.images.length > 0) {
      const _images: Array<IImg> = record?.images.filter((v) => v && v.length > 4).map((v, i) => {
        return { id: `image-${i}`, url: v };
      });
      setImages(_images);
    }
  }, [record]);

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <EditHashtagsModal
        modalProps={ modalProps }
        artworkId={+(record?.id ?? 0)}
        hashtagKeys={record?.hashtags!.filter((v) => !v.key.startsWith('0')).map((v) => v.key) ?? []}
        close={() => {
          queryResult!.refetch();
          close();
        }}
      />
      <Card
        type="inner"
        title="기본정보"
        extra={
          <>
          {
            record &&
            <Button
              size="small"
              disabled={!active}
              onClick={() => {
                updateArtwork(record?.id);
              }}
            >{`이미지 순서저장`}</Button>
          }
          <Button
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => {
              show();
            }}
          >{`해쉬택 수정`}</Button>
          </>
        }
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <div>
            {
              images ?
                <DragDropContext onDragEnd={handleChange}>
                  <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <Image.PreviewGroup
                      preview={{
                        countRender: (current, total) => {
                          const title = record?.title ?? '';
                          const count = `(${current} / ${total})`;
                          return `${title} ${count}`;
                        }
                      }}
                    >
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {images.map(({id, url}, i) => (
                          <Draggable
                            key={id}
                            draggableId={id}
                            index={i}
                          >
                            {(provided, snapshot) =>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Image
                                  src={url}
                                  style={{ border: '1px solid #888' }}
                                />
                              </div>
                            }
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </Image.PreviewGroup>
                  )}
                  </Droppable>
                </DragDropContext> : <Tag>자료없음</Tag>
            }
            </div>
          </Col>

          <Col span={12}>
            {
              record?.review?.ko
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.review.ko} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={12}>
            {
              record?.review?.en
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.review.en} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>

        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom:16 }}>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>작가</Title>
            {
              record?.artist ? <>
                <Link to={`/artists/show/${record?.artist.id}`}>
                  {
                    record && record.artist && record.artist.user &&
                    <Avatar
                      size={32}
                      shape="square"
                      src={avatarUrl(record?.artist?.user?.avatar)}
                      alt={record?.artist?.user?.username}
                    />
                  }
                  <Text style={{marginLeft: 8}}>{ record?.artist?.name }</Text>
                </Link>
                <Tag color={sellerTypeColor(record?.artist?.sellerType)} style={{marginLeft: 4}}>{sellerTypeName(record?.artist?.sellerType)}</Tag>
              </>
              : <Text>{record?.artistName}</Text>
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>작품타이틀 (검색)</Title>
            <Text>{record?.title}</Text>
          </Col>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>해쉬택</Title>
          {
            record?.hashtags &&
            record?.hashtags!.length > 0 ?
            record?.hashtags.map((v: IHashtag) => (
              <Tag key={v.key} color="indigo">{v.title}</Tag>
            )) : <Tag>기록없음</Tag>
          }
          </Col>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>작품소개</Title>
            {
              record?.body
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.body} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>비고</Title>
            {
              record?.note ? <Text>{record?.note}</Text> : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom:16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>소재</Title>
            {record?.medium ? <Text>{record?.medium}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>분류</Title>
            {record?.category
            ? <>
                <Tag>{categoryName(record?.category)}</Tag>
                <Text style={{fontSize: 11, marginLeft: 4}}>{record?.category === 'LIMITED_EDITION' || record?.category === 'OPEN_EDITION' ? record?.edition : ''}</Text>
              </>
            : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>색상</Title>
            <Text>{record?.color}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>프레임</Title>
            <Text>{record?.framing === 'FRAMED' ? '유' : '무'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>작품 가로/세로</Title>
            { record?.width && record?.height ? <>
                <Text>{(record?.width / 10).toFixed(1).toString()}cm</Text>
                {' x '}
                <Text>{(record?.height / 10).toFixed(1).toString()}cm</Text>
              </>
              : `기록없음` }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>액자포함 가로/세로</Title>
            { record?.fullWidth && record?.fullHeight ? <>
                <Text>{(record?.fullWidth / 10).toFixed(1).toString()}cm</Text>
                {' x '}
                <Text>{(record?.fullHeight / 10).toFixed(1).toString()}cm</Text>
              </>
              : `기록없음` }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom:16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>즉시구매가</Title>
            { record?.buyItNowPrice ? <Text>{(record?.buyItNowPrice).toLocaleString()}원</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최소구매가</Title>
            { record?.reservePrice ? <Text>{(record?.reservePrice).toLocaleString()}원</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>경매시작가</Title>
            { record?.startingPrice ? <Text>{(record?.startingPrice).toLocaleString()}원</Text> : <Tag>제로베이스</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>제작년도</Title>
            <Text>{record?.producedIn}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>방향</Title>
            {
              record &&
              <Image width={24} src={orientationImageUrl(record?.orientation)} preview={false} alt={record?.orientation} />
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>사이즈</Title>
            <Text>{record?.size}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>묶음배송</Title>
            {record?.isCombinable ? <Tag>가능</Tag> : <Tag>불가</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>지연발송</Title>
            <Text>{record?.shippableWithin}주</Text>
          </Col>
        </Row>
      </Card>
      <Card type="inner" title="리셀정보" style={{marginBottom: 24}}>
      {
        record?.artist?.id === record?.owner?.id ?
        <Row gutter={[16, 16]}><Col span={24}><Tag>기록없음</Tag></Col></Row> :
        <Row gutter={[16, 16]} style={{ marginBottom:16 }}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>상태</Title>
            <Tag>{ record?.condition }</Tag>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>인증서</Title>
            <Tag>{ record?.certificate === 'NA' ? '인증서없음' : '인증서있음' }</Tag>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>구매처</Title>
            {record?.place ? <Text>{ record?.place }</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>구매년도</Title>
            {record?.boughtIn ? <Text>{ record?.boughtIn }</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>구매가</Title>
            { record?.paidAmount ? <Text>{(record?.paidAmount).toLocaleString()}원</Text> : <Tag>기록없음</Tag> }
          </Col>
        </Row>
      }
      </Card>
      {record?.auctions &&
        <Card type="inner" title="관련옥션" style={{marginBottom: 24}}>
        {
          record?.auctions.length > 0 ? record?.auctions!.map((auction: IAuction, i: number) => {
            return <Row key={`artist-row--${i}`} gutter={[16, 16]}>
              <Col key={`artist-col-${i}`} span={4}>
              { i === 0 ?
                <Row gutter={[16, 16]}>
                  <Col span={24}><Title level={5} style={{fontSize: 11}}>작가</Title></Col>
                </Row> :
                <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
              }
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Text ellipsis style={{marginRight: 4}}>{ auction.artwork?.artist?.name ?? 'n/a' }</Text>
                    {
                      auction && auction.artwork && auction.artwork.artist && auction.artwork.artist &&
                      <Tag color={sellerTypeColor(auction.artwork?.artist?.sellerType)}>{sellerTypeName(auction.artwork?.artist?.sellerType)}</Tag>
                    }
                  </Col>
                </Row>
              </Col>
              <Col key={`auction-col-${i}`} span={20}>
              { i === 0 ?
                <Row gutter={[16, 16]}>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>작품</Title></Col>
                  <Col span={6}><Title level={5} style={{fontSize: 11}}>작품명/추정가</Title></Col>
                  <Col span={4}><Title level={5} style={{fontSize: 11}}>시작</Title></Col>
                  <Col span={4}><Title level={5} style={{fontSize: 11}}>종료</Title></Col>
                  <Col span={2}><Title level={5} style={{fontSize: 11}}>입찰수</Title></Col>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>입찰/낙찰가</Title></Col>
                  <Col span={2}><Title level={5} style={{fontSize: 11}}>상태</Title></Col>
                </Row>
                :  <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
              }
                <Row key={`auction-row-${i}`} gutter={[16, 16]}>
                  <Col key={`auction-col1-${i}`} span={3}>
                    <Link to={`/auctions/show/${auction?.id}`}>
                      <Avatar
                        size={64}
                        shape="square"
                        src={firstImageUrl(auction?.images)}
                        alt={auction?.title}
                      />
                    </Link>
                  </Col>
                  <Col key={`auction-col2-${i}`} span={6}>
                    <div><Text ellipsis>{auction?.title}</Text></div>
                    <div><Tag style={{fontSize: 9}}>{auction?.estimate!.toLocaleString()}원</Tag></div>
                  </Col>
                  <Col key={`auction-col3-${i}`} span={4}>
                    <div><ReactTimeAgo date={Date.parse(auction?.startTime)} locale="ko" /></div>
                    <div><Tag style={{fontSize: 9}}>{dayjs(auction.startTime).format('YY-MM-DD HH:mm')}</Tag></div>
                  </Col>
                  <Col key={`auction-col4-${i}`} span={4}>
                    <div><ReactTimeAgo date={Date.parse(auction?.endTime)} locale="ko" /></div>
                    <div><Tag style={{fontSize: 9}}>{dayjs(auction.endTime).format('YY-MM-DD HH:mm')}</Tag></div>
                  </Col>
                  <Col key={`auction-col5-${i}`} span={2}>
                    <Text>{auction?.bidCount!.toLocaleString()}건</Text>
                  </Col>
                  <Col key={`auction-col6-${i}`} span={3}>
                    <Text>{auction?.lastBidAmount!.toLocaleString()}원 ({auction.status==='ENDED' ? '낙찰가' : '입찰가'})</Text>
                  </Col>
                  <Col key={`auction-col7-${i}`} span={2}>
                    <Tag color={auctionStatusColor(auction?.status)}>{auctionStatusName(auction?.status )}</Tag>
                  </Col>
                </Row>
              </Col>
            </Row>
          }) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
        }
        </Card>
      }
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card type="inner" title="작가정보">
          {
            record?.artist ? <>
              <Row gutter={[16, 16]}>
                <Col span={8}><Title level={5} style={{fontSize: 11}}>작가</Title></Col>
                <Col span={8}><Title level={5} style={{fontSize: 11}}>사용자명</Title></Col>
                <Col span={8}><Title level={5} style={{fontSize: 11}}>이메일</Title></Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Text style={{marginRight: 4}}>{record?.artist?.name}</Text>
                  <Tag color={sellerTypeColor(record?.artist?.sellerType)}>{sellerTypeName(record?.artist?.sellerType)}</Tag>
                </Col>
                <Col span={8}>
                  <Link to={`/users/show/${record?.artist?.user?.id}`}>
                    {
                      record && record.artist && record.artist.user &&
                      <Avatar
                        size={32}
                        src={avatarUrl(record?.artist?.user?.avatar)}
                        alt={record?.artist?.user?.username}
                      />
                    }
                    <Text style={{marginLeft: 8}}>{record?.artist?.user?.username}</Text>
                  </Link>
                </Col>
                <Col span={8}>
                  <Text>{record?.artist?.user?.email}</Text>
                </Col>
              </Row></>
            : <Row><Col span={24}><Tag>작가 기록없음</Tag></Col></Row>
          }
          </Card>
        </Col>
        <Col span={12}>
          <Card type="inner" title="오너정보">
          {
            record?.owner ? <>
              <Row gutter={[16, 16]}>
                <Col span={8}><Title level={5} style={{fontSize: 11}}>오너</Title></Col>
                <Col span={8}><Title level={5} style={{fontSize: 11}}>사용자명</Title></Col>
                <Col span={8}><Title level={5} style={{fontSize: 11}}>이메일</Title></Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Text style={{marginRight: 4}}>{record?.owner?.name}</Text>
                  <Tag color={sellerTypeColor(record?.owner?.sellerType)}>{sellerTypeName(record?.owner?.sellerType)}</Tag>
                </Col>
                <Col span={8}>
                  <Link to={`/users/show/${record?.owner?.user?.id}`}>
                    {
                      record && record.owner && record.owner.user &&
                      <Avatar
                        size={32}
                        src={avatarUrl(record?.owner?.user?.avatar)}
                        alt={record?.owner?.user?.username}
                      />
                    }
                    <Text style={{marginLeft: 8}}>{record?.owner?.user?.username}</Text>
                  </Link>
                </Col>
                <Col span={8}>
                  <Text>{record?.owner?.user?.email}</Text>
                </Col>
              </Row></>
            : <Row><Col span={24}><Tag>오너 기록없음</Tag></Col></Row>
          }
          </Card>
        </Col>
      </Row>
    </Show>
  );
};
