import { FundOutlined, TrademarkOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import {
  Image,
  Button,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  ShowButton,
  Space,
  Row,
  Table,
  TagField,
  TextField,
  useModal,
  useTable
} from "@pankod/refine-antd";
import { ACCESS_TOKEN } from "../../constants";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { firstImageUrl } from "helpers/artwork";
import dayjs from "dayjs";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import { IAuction } from "interfaces";
import { RankingModal } from 'pages/auctions/components/auction/ranking-modal';
import ReactTimeAgo from "react-time-ago";
import { useRef } from 'react';

const { Link } = routerProvider;
const { useLocation } = routerProvider;

export const AuctionList: React.FC<IResourceComponentsProps> = () => {
  // useModal hook
  const { modalProps, show, close } = useModal()
  const loc = useLocation();
  const params = loc.search.substring(1).split('&');
  const apiUrl = useApiUrl();
  const auctionId = useRef<number>();

  const onChangeHandler = (e: any) => {
    const v = e.target?.value;
    if (+v > 0) {
      auctionId.current = +v;
    }
  }

  const moveToPage = async (id: number) => {
    const res = await fetch(`${apiUrl}/auctions/pages/closest?${params[0]}&id=${auctionId.current}`)
    const { data: page } = (await res.json()) as { data: number }
    const paramString = params.map((v) => {
      if (v.startsWith('current=')) {
        return `current=${page}`;
      }
      return v;
    }).join('&')
    const targetUrl = `${window.location.origin}${loc.pathname}?${paramString}`
    window.location.replace(targetUrl);
  }

  const { tableProps, sorter } = useTable<IAuction>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  return (
    <List
      headerButtons={({ defaultButtons }) => (
      <>
        <Button
          icon={<FundOutlined />}
          onClick={() => {
            show();
          }}
        >현재 Ranking 확인</Button>
        <Input placeholder="Auction #" onChange={onChangeHandler} />
        <Button
          icon={<VerticalLeftOutlined />}
          onClick={async () => {
            if (auctionId.current && auctionId.current > 0) {
              console.log(`${apiUrl}/auctions/pages/closest?${params[0]}&id=${auctionId.current}&`)
              await moveToPage(auctionId.current);
            }
          }}
        >페이지 이동</Button>
        { defaultButtons }
      </>
      )}
    >
      <RankingModal modalProps={ modalProps } />
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column<IAuction>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="image"
          title="작품사진"
          render={(_, record) => (
            <Link to={`/auctions/show/${record?.id}`}>
              <Image
                width={72}
                height={72}
                // shape="square"
                src={firstImageUrl(record?.images)}
                alt={record?.title}
                style={{
                  // width: 100,
                  maxHeight: '100px',
                  objectFit: 'scale-down',
                }}
              />
            </Link>
          )}
        />
        <Table.Column<IAuction>
          dataIndex="title"
          key="title"
          title="제목/작가"
          render={(_, record) => <div>
            <div><TextField value={record?.title} style={{fontWeight: "bold"}} /></div>
            <Row>
              <TextField value={record?.artwork?.artistName ?? 'n/a'} style={{marginRight: 4}} />
              <div>
              {
                record?.channel && <TagField value={record?.channel ?? 'n/a'} />
              }
              </div>
              <div>
              {
                record?.resellerId && <TrademarkOutlined color="#ff2f2f" />
              }
              </div>
            </Row>
            <div style={{fontSize:10}}>
            {
              record?.scheduledAt !== null ? <div>(예상택배발송: {dayjs(record?.scheduledAt).format('YYYY-MM-DD')})</div> : ''
            }
            </div>
          </div>}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="subtitle"
          key="subtitle"
          title="기획전명"
          render={(value) => <TextField value={value} style={{fontWeight: "bold"}} />}
          defaultSortOrder={getDefaultSortOrder("subtitle", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="startTime"
          key="startTime"
          title="시작시각"
          render={(value) => <ReactTimeAgo date={Date.parse(value)} locale="ko" />}
          defaultSortOrder={getDefaultSortOrder("startTime", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="closingTime"
          key="closingTime"
          title="종료시각"
          render={(value) => <ReactTimeAgo date={Date.parse(value)} locale="ko" />}
          defaultSortOrder={getDefaultSortOrder("closingTime", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="estimate"
          key="estimate"
          title="추정가"
          render={(value) => <TextField value={value.toLocaleString()} />}
          defaultSortOrder={getDefaultSortOrder("estimate", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="startingPrice"
          key="startingPrice"
          title="시작가"
          render={(_, record) => (
            record?.bidIncrement && record?.bidIncrement > 0
            ? (record?.startingPrice && record?.startingPrice > 0
              ? <TextField value={record?.startingPrice.toLocaleString()} />
              : <TagField value='제로베이스' />
            )
            : <TagField color="red" value='즉구ONLY' />
          )}
          // defaultSortOrder={getDefaultSortOrder("startingPrice", sorter)}
          // sorter
        />
        <Table.Column<IAuction>
          dataIndex="lastBidAmount"
          key="lastBidAmount"
          title="현재가"
          render={(_, record) => <>{
            record?.order?.orderStatus === 'PAID'
            ? <TextField value={`✅${record.lastBidAmount.toLocaleString()}`} />
            : <TextField value={record.lastBidAmount.toLocaleString()} />
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("lastBidAmount", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="bidCount"
          key="bidCount"
          title="입찰수"
          render={(_, record) => <>{
            record?.endTime === record?.closingTime
              ? <NumberField value={record?.bidCount.toLocaleString()} />
              : <TextField value={`🔥${record?.bidCount.toLocaleString()}`} />
          }</>}
          defaultSortOrder={getDefaultSortOrder("bidCount", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="status"
          key="status"
          title="상태"
          render={(value) => <TagField
            color={auctionStatusColor(value)}
            value={auctionStatusName(value)}
          />}
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="updatedAt"
          key="updatedAt"
          title="최근수정"
          render={(value) => <ReactTimeAgo date={Date.parse(value)} locale="ko" />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column<IAuction>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
