import { DeleteOutlined, EyeOutlined, FileOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  Modal,
  Radio,
  RcFile,
  Row,
  Select,
  Tag,
  Typography,
  Upload,
  UploadFile,
  getValueFromEvent,
  message,
  useForm,
  useModal
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCustomMutation, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import type { UploadProps } from 'antd';
import axios from 'axios';
import dayjs from "dayjs";
import { articleCategoryColor, articleCategoryName } from 'helpers/article';
import { categoryName, firstImageUrl } from 'helpers/artwork';
import { sellerTypeColor, sellerTypeName } from 'helpers/seller';
import { avatarUrl } from 'helpers/user';
import { IArticle, IArtist, IArtwork, IAuction, IPack } from "interfaces";
import { SelectArticleModal } from 'pages/packs/components/article/select-article-modal';
import { SelectArtistModal } from 'pages/packs/components/artist/select-artist-modal';
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";
import { useMediaQuery } from 'react-responsive';
import { ACCESS_TOKEN } from "../../constants";

const { Title, Text } = Typography;
const { Link } = routerProvider;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const PackEdit: React.FC<IResourceComponentsProps> = () => {
  // useMediaQuery hook
  const isBigScreen = useMediaQuery({ minWidth: 1280 })
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 })

  // useModal hook
  const { modalProps: artistModalProps, show: artistModalShow, close: artistModalClose } = useModal()
  const { modalProps: articleModalProps, show: articleModalShow, close: articleModalClose } = useModal()

  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useUpdate hook
  const { mutate: mutatePack } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IPack>({
    redirect: false,
  });

  // useState() hooks
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [bannerFileList, setBannerFileList] = useState<UploadFile[]>([]);
  const [mobileFileList, setMobileFileList] = useState<UploadFile[]>([]);
  
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");
  const [artists, setArtists] = useState<IArtist[]>([]);
  const [uri, setUri] = useState('');

  // more constants
  const record = queryResult?.data?.data as IPack;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  //?-------------------------------------------------------------------------//
  //? file upload
  //?-------------------------------------------------------------------------//

  const uploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: fileList,
    action: `${apiUrl}/packs/${record?.id}/image`,
    headers: headers,
    onChange({ file, fileList: newFileList }) {
      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            // you can skip the type here
            url: file.response?.image,
          }];
        }

        form.setFieldsValue({ image: file.response?.image });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        const values = {
          ...inputData,
          image: images ? images[0] : null,
          simple: false,
          compositeIds: artists
            .filter((v) => {
              return (v.artworks && v.artworks.length > 0) ? true : false;
            })
            .map((v) => {
              return {
                artistId: v.id,
                artworkIds: v.artworks?.map((v) => v.id)
              }
            }
          ),
        }

        mutatePack({
          resource: `packs`, values, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "packs", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`기획전 (${record?.title}) 배너`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ image: null });
          setFileList([])
        } catch (e) {
          console.error('removing an image from S3 failed.')
        }
      }
    }
  }

  // this is a quick and dirty solution for bannerImage
  const bannerUploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: bannerFileList,
    action: `${apiUrl}/packs/${record?.id}/banner-image`,
    headers: headers,
    onChange({ file, fileList: newFileList }) {
      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            url: file.response?.bannerImage, // this would be optional
          }];
        }

        form.setFieldsValue({ bannerImage: file.response?.bannerImage });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        const values = {
          ...inputData,
          bannerImage: images ? images[0] : null,
          simple: false,
          compositeIds: artists
            .filter((v) => {
              return (v.artworks && v.artworks.length > 0) ? true : false;
            })
            .map((v) => {
              return {
                artistId: v.id,
                artworkIds: v.artworks?.map((v) => v.id)
              }
            }
          ),
        }

        mutatePack({
          resource: `packs`, values, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "packs", invalidates: ["detail"] })
          },
        })
      }
      setBannerFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`이미지: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ bannerImage: null });
          setBannerFileList([]);
        } catch (e) {
          console.error('removing from S3 failed.')
        }
      }
    }
  }

    // this is a quick and dirty solution for mobileImage
  const mobileUploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: mobileFileList,
    action: `${apiUrl}/packs/${record?.id}/mobile-image`,
    headers: headers,
    onChange({ file, fileList: newFileList }) {
      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            url: file.response?.mobileImage, // this would be optional
          }];
        }

        form.setFieldsValue({ mobileImage: file.response?.mobileImage });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        const values = {
          ...inputData,
          mobileImage: images ? images[0] : null,
          simple: false,
          compositeIds: artists
            .filter((v) => {
              return (v.artworks && v.artworks.length > 0) ? true : false;
            })
            .map((v) => {
              return {
                artistId: v.id,
                artworkIds: v.artworks?.map((v) => v.id)
              }
            }
          ),
        }

        mutatePack({
          resource: `packs`, values, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "packs", invalidates: ["detail"] })
          },
        })
      }
      setMobileFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`이미지: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ mobileImage: null });
          setMobileFileList([]);
        } catch (e) {
          console.error('removing from S3 failed.')
        }
      }
    }
  }

  //?-------------------------------------------------------------------------//
  //? custom hooks
  //?-------------------------------------------------------------------------//

  const { mutate: notify } = useCustomMutation();

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  const onClickStartTimeOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ startTime: day });
    //return day.toString();
  }

  const onClickEndTimeOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ endTime: day });
    // return day.toString();
  }

  const onClickScheduledAtOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ scheduledAt: day });
    //return day.toString();
  }

  const onArtistModalOpen = () => {
    artistModalShow();
  }

  const onArtistDelete = (i: number) => {
    const newArtists = artists.filter((v) => v.id !== i)
    setArtists(newArtists);
    message.info(`화면 하단의 Save 버튼을 눌러야만, DB에 변경사항이 저장됩니다.`);
  }

  const artistCallback = (newArtist: IArtist) => {
    if (artists.find((i) => i.id === newArtist.id)) {
      const newArtists = artists.map((j: IArtist) => {
        if (j.id === newArtist.id) {
          const existingArtworkIds = j.artworks ? j.artworks.map((v: IArtwork) => v.id) : [];
          const existingArtworks = j.artworks ? j.artworks : [];
          const addedArtworks = newArtist.artworks ? newArtist.artworks.filter((v: IArtwork) => !existingArtworkIds.includes(v.id)) : [];
          j.artworks = [...existingArtworks, ...addedArtworks]
        }
        return j;
      })
      setArtists(newArtists);
    } else  {
      if (newArtist.artworks && newArtist.artworks.length > 0) {
        const newArtists = [ ...artists, newArtist ];
        setArtists(newArtists)
      }
    }
  }

  const onArticleModalOpen = () => {
    articleModalShow();
  }

  const onArticleDelete = async (id: number) => {
    try {
      const res = await axios({
        method: 'delete',
        url: `${apiUrl}/packs/${record?.id}/articles/${id}`,
        headers: headers,
      });
      message.success('아티클이 삭제되었습니다.')
    } catch (e) {
      message.error('관련 아티클 삭제시 에러 발생')
    }
    await queryResult?.refetch();
  }

  const articleCallback = async (ids: number[]) => {
    if (ids.length > 0) {
      try {
        const res = await axios({
          method: 'post',
          url: `${apiUrl}/packs/${record?.id}/articles`,
          headers: headers,
          data: { ids },
        });
        message.success('지정한 아티클을 옥션 아티클로도 등록했습니다. (완전 🐕꿀)')
      } catch (e) {
        message.error('관련 아티클 저장시 에러 발생')
      }
      await queryResult?.refetch();
    }
  }

  const onClickKakaoNotification = (id: number) => {
    notify({
      url: `${process.env.REACT_APP_API_URL}/packs/${id}/notify-kakao`,
      method: 'post',
      values: {}
    })

  }

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('packs-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    console.log(`[useEffect hook] <IPack> data has been changed. => `, record)
    // if it has image
    if (record?.image) {
      const url = record?.image;
      if (! url.includes('pack.png')) { // doesn't have any default image in the server side though.
        const filename = url.replace(/^.*[\\/]/, '');
        const extention = url.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
        setFileList([{
          uid: '-10',
          name: filename,
          status: 'done',
          type: `image/${extention}`,
          url: url,
        }])
      }
    }
    // if it has mobileImage
    if (record?.bannerImage) {
      const url = record?.bannerImage;
      if (! url.includes('pack.png')) { // doesn't have any default image in the server side though.
        const filename = url.replace(/^.*[\\/]/, '');
        const extention = url.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
        setBannerFileList([{
          uid: '-20',
          name: filename,
          status: 'done',
          type: `image/${extention}`,
          url: url,
        }])
      }
    }
    // if it has mobileImage
    if (record?.mobileImage) {
      const url = record?.mobileImage;
      if (! url.includes('pack.png')) { // doesn't have any default image in the server side though.
        const filename = url.replace(/^.*[\\/]/, '');
        const extention = url.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
        setMobileFileList([{
          uid: '-20',
          name: filename,
          status: 'done',
          type: `image/${extention}`,
          url: url,
        }])
      }
    }
    // if it has artists
    if (record?.artists) {
      const artistsWithExtras = record?.artists!.map((artist: IArtist) => {
        return {
          ...artist,
          auctions: artist.auctions,
          artworks: artist.auctions ? artist.auctions.filter((v) => v).map((v: IAuction) => v.artwork) : []
        }
      });
      setArtists(artistsWithExtras);
    }
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<EyeOutlined />}
            onClick={(): void => goto("packs", record?.id!)}
          >Show</Button>
          {defaultButtons}
        </>
      )}
    >
      <SelectArticleModal modalProps={ articleModalProps } close={ articleModalClose } callback={ articleCallback } />
      <SelectArtistModal modalProps={ artistModalProps } close={ artistModalClose } callback={ artistCallback } />
      <Form
        {...formProps} 
        layout="vertical"
        onFinish={(values) => {
          console.log(`forms: `, formProps, values);
          formProps.onFinish && formProps.onFinish({
            ...values,
            simple: false,
            compositeIds: artists
              .filter((v) => {
                return (v.artworks && v.artworks.length > 0) ? true : false;
              })
              .map((v) => {
                return {
                  artistId: v.id,
                  artworkIds: v.artworks?.map((v) => v.id)
                }
              }
            ),
          });
          // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
          setTimeout(() => {
            if (uri.length > 0) {
              push(`../packs?${uri}`);
            } else {
              goBack();
            }
          }, 500)
        }}
      >
        <Card
          type="inner"
          title="관련 아티클"
          extra={
            <Button
              size="small"
              icon={<FileOutlined />}
              onClick={onArticleModalOpen}
            >
              아티클 선택
            </Button>
          }
          style={{marginBottom: 24}}
        >
          <Row gutter={[16, 16]}>
            {
              record?.articles && record?.articles.length > 0
              ? record?.articles!
                .sort((a: IArticle, b: IArticle) => a.id > b.id ? -1 : 1)
                .map((article, i) => {
                return (
                  <Col key={`article-col-${i}`} span={isBigScreen? 3 : (isSmallScreen? 6 : 4)}>
                    <Card
                      key={`article-key-${i}`} 
                      bordered={true}
                      size="small"
                      style={{ padding: 2, border: '1px solid #ccc' }}
                      cover={
                        <img src={article.images![0]}
                          alt={article.title}
                          style={{ height: 160 }} />
                      }
                    >
                      <Text ellipsis style={{ fontSize: 11}}>{ article.subtitle }</Text>
                      <Button
                        size='small'
                        danger
                        icon={<DeleteOutlined />}
                        style={{ position: 'absolute', top: 124, right: 10 }}
                        onClick={() => onArticleDelete(article.id)} />
                      <Tag
                        color={articleCategoryColor(article.category)}
                        style={{ fontSize: 10, position: 'absolute', top: 124, left: 10 }}
                      >
                        {articleCategoryName(article.category)}
                      </Tag>
                      <Tag
                        style={{ fontSize: 10, position: 'absolute', top: 10, left: 10 }}
                      >
                        { article.title }
                      </Tag>
                    </Card>
                  </Col>
                );
              }) : <Col span={24}><Text>관련 아티클 정보가 없습니다. 아티클 선택 버튼을 누르세요.</Text></Col>
            }
          </Row>
        </Card>
        <Card
          type="inner"
          title="관련 작가·작품"
          extra={
            <Button
              size="small"
              icon={<UserOutlined />}
              onClick={onArtistModalOpen}
            >
              작가·작품 선택
            </Button>
          }
          style={{marginBottom: 24}}
        >
          {
            artists && artists.length > 0
            ? artists!.map((artist, i: number) => {
              return(<Row
              key={`artwork-row-${artist.id}`}
              gutter={[16, 16]}
              style={{marginBottom: 16}}
              >
                <Col key={`artist-col1-${i}`} span={6}>
                { i === 0 ?
                  <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                    <Col span={24}><Title level={5} style={{fontSize: 11}}>작가</Title></Col>
                  </Row> :
                  <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
                }
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Link to={`/artists/show/${artist?.id}`}>
                        {
                          artist && artist.user &&
                          <Avatar
                            size={32}
                            src={avatarUrl(artist.user?.avatar)}
                            alt={artist?.user?.username}
                            style={{ marginRight: 8 }}
                          />
                        }
                      </Link>
                      <Tag color={sellerTypeColor(artist.sellerType)}>{sellerTypeName(artist.sellerType)}</Tag>
                      <Button
                        danger
                        size='small'
                        icon={<DeleteOutlined />}
                        onClick={() => onArtistDelete(artist.id)} />
                      <div><Text ellipsis>{ artist.name ?? '무명' }</Text></div>
                      <div><Text ellipsis>{ artist.user?.username }</Text></div>
                    </Col>
                  </Row>
                </Col>
                <Col span={18}>
                { i === 0 ?
                  <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                    <Col span={4}><Title level={5} style={{fontSize: 11}}>작품</Title></Col>
                    <Col span={8}><Title level={5} style={{fontSize: 11}}>작품명</Title></Col>
                    <Col span={2}><Title level={5} style={{fontSize: 11}}>구분</Title></Col>
                    <Col span={4}><Title level={5} style={{fontSize: 11}}>추정가</Title></Col>
                    <Col span={6}><Title level={5} style={{fontSize: 11}}>사이즈</Title></Col>
                  </Row>
                  :  <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
                }
                {
                  artist.artworks && artist.artworks.length > 0
                  ? artist.artworks!.map((artwork: IArtwork, i: number) => {
                    return <Row key={`artwork-row-${i}`} gutter={[16, 16]}>
                      <Col key={`artwork-col1-${i}`} span={4}>
                        <Link to={`/artworks/show/${artwork?.id}`}>
                          <Avatar
                            size={64}
                            shape="square"
                            src={firstImageUrl(artwork?.images)}
                            alt={artwork?.title}
                          />
                        </Link>
                      </Col>
                      <Col key={`artwork-col2-${i}`} span={8}>
                        <div><Text ellipsis>{artwork?.title}</Text></div>
                      </Col>
                      <Col key={`artwork-col4-${i}`} span={2}>
                        <Tag>{categoryName(artwork?.category)}</Tag>
                      </Col>
                      <Col key={`artwork-col3-${i}`} span={4}>
                        <Text>{artwork?.estimate!.toLocaleString()}원</Text>
                      </Col>
                      <Col key={`artwork-col5-${i}`} span={6}>
                        <Tag>{artwork?.size}</Tag>
                        <Text>{(artwork?.width/10).toFixed(1)}cm x {(artwork?.height/10).toFixed(1)}cm</Text>
                      </Col>
                    </Row>
                  })
                  : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
                }
                </Col>
              </Row>)
            })
            : <Row gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={24}><Text>선택한 작가·작품이 없습니다. 작가·작품 선택 버튼을 누르세요.</Text></Col>
            </Row>
          }
        </Card>
        <Card type="inner" title="기획전 정보">
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item label="포스터 이미지">
                <Form.Item
                  name="image"
                  valuePropName="[fileList]"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                <Upload { ...uploadProps }>
                  {fileList.length < 1 && '+ Upload'}
                </Upload>
                <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handlePreviewModalClose}>
                  <img alt="banner" style={{ width: '100%' }} src={previewImage} />
                </Modal>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="기획전 상세이미지(PC)">
                <Form.Item
                  name="bannerImage"
                  valuePropName="[bannerFileList]"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                <Upload { ...bannerUploadProps }>
                  {bannerFileList.length < 1 && '+ Upload'}
                </Upload>
                <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handlePreviewModalClose}>
                  <img alt="banner" style={{ width: '100%' }} src={previewImage} />
                </Modal>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="기획전 상세이미지(MO)">
                <Form.Item
                  name="mobileImage"
                  valuePropName="[mobileFileList]"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                <Upload { ...mobileUploadProps }>
                  {mobileFileList.length < 1 && '+ Upload'}
                </Upload>
                <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handlePreviewModalClose}>
                  <img alt="banner" style={{ width: '100%' }} src={previewImage} />
                </Modal>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="타입"
                name="packType"
              >
                <Radio.Group
                  options={[
                    { label: '옥션', value: 'auctions' },
                    { label: '일반판매', value: 'buyItNows' }, 
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="제목 ✋ (관련옥션 적용)"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="부제목"
                name="subtitle"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="기획전 설명"
                name="summary"
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="옥션 안내문 ✋ (관련옥션 적용; 관련내용의 변경을 원할경우만 입력)"
                name="auctionFinePrint"
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="시작시각 ✋ (관련옥션 적용)"
                name="startTime"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onClickStartTimeOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="종료시각 ✋ (관련옥션 적용)"
                name="endTime"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onClickEndTimeOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="발송 예정시각 ✋ (관련옥션 적용)"
                name="scheduledAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onClickScheduledAtOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="상태 ✋ (관련옥션 적용)"
                name="status"
              >
                <Select
                  options={[
                    { label: '준비중', value: 'PREPARING', },
                    { label: '진행중', value: 'ONGOING', },
                    { label: '종료', value: 'ENDED', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="경매 진행주"
                name="weeks"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="옥션 종료시각 변경"
                name="offset"
                initialValue={''}
              >
                <Select
                  options={[
                    { label: '옥션 변경하지 않음', value: '', },
                    { label: '같은 시각으로 통일', value: '0', },
                    { label: '1분 간격으로 변경', value: '1', },
                    { label: '2분 간격으로 변경', value: '2', },
                    { label: '3분 간격으로 변경', value: '3', },
                    { label: '4분 간격으로 변경', value: '4', },
                    { label: '5분 간격으로 변경', value: '5', },
                    { label: '6분 간격으로 변경', value: '6', },
                    { label: '7분 간격으로 변경', value: '7', },
                    { label: '8분 간격으로 변경', value: '8', },
                    { label: '9분 간격으로 변경', value: '9', },
                    { label: '10분 간격으로 변경', value: '10', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="판매채널 ✋ (관련옥션 적용)"
                name="channel"
              >
                <Select
                  // placeholder='삼성물산 옵션'
                  options={[
                    { label: '플리옥션', value: 'domestic', },
                    { label: '플리옥션 글로벌', value: 'global', },
                    { label: '삼성물산 서초', value: 'seocho', },
                    { label: '삼성물산 거제', value: 'geoje', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="전시위치 ✋ (관련옥션 적용)"
                name="online"
              >
                <Radio.Group
                  options={[
                    { label: '온라인', value: true },
                    { label: '오프라인', value: false },
                  ]}
                  value={record?.online}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="옥션가격정책 ✋ (관련옥션 적용)"
                name="auctionIsShippable"
              >
                <Radio.Group
                  options={[
                    { label: '배송비 정상계산', value: 'regular' },
                    { label: '배송비 무료', value: 'free' }, 
                  ]}
                  value={record?.notify}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="푸시알림예약"
                name="notify"
              >
                <Radio.Group
                  options={[
                    { label: 'ON', value: true },
                    { label: 'OFF', value: false }, 
                  ]}
                  value={record?.notify}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="활성상태"
                name="isPublished"
              >
                <Radio.Group
                  options={[
                    { label: '노출', value: true },
                    { label: '숨김', value: false }, 
                  ]}
                  value={record?.isPublished}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <div style={{ fontWeight: 'bold', marginBottom: 8 }}>카톡발송</div>
              <Button
                onClick={() => {
                  onClickKakaoNotification(record.id)
                  message.success('🗨️ 카톡알림 발송했습니다!')
                }}
              >
                카톡으로 팩노출 통보
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </Edit>
  );
};
