import { EyeOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  RcFile,
  Row,
  Select, Upload,
  UploadFile,
  UploadProps,
  getValueFromEvent,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import { ACCESS_TOKEN } from '../../constants';
import { IBanner } from 'interfaces';
import { useEffect, useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import dayjs, { Dayjs } from 'dayjs';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const BannerEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useCreate hook
  const { mutate: getSignedUrl } = useCreate();

  // useUpdate hook
  const { mutate: mutateBanner } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<Omit<IBanner, 'id'>>({
    redirect: false,
  });

  // useState hooks
  const [startedAt, setStartedAt] = useState<Dayjs | null>(null);
  const [endedAt, setEndedAt] = useState<Dayjs | null>(null);
  const [bannerType, setBannerType] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [fileList, setFileList] = useState<UploadFile[]>([]);  
  const [mobileFileList, setMobileFileList] = useState<UploadFile[]>([]);

  const [uri, setUri] = useState('');
  const [createObjectUrl, setCreateObjectUrl] = useState('');

  // more constants
  const record = queryResult?.data?.data as IBanner;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  //?-------------------------------------------------------------------------//
  //? file upload (note that this doesn't make use of S3 direct uploading)
  //?-------------------------------------------------------------------------//

  const uploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: fileList,
    action: `${apiUrl}/banners/${record?.id}/image`,
    headers: headers,
    // beforeUpload: (file, files) => {
    //   // setImageFile(file as RcFile);
    //   if (createObjectUrl) {
    //     URL.revokeObjectURL(createObjectUrl);
    //   }
    //   const url = URL.createObjectURL(file);
    //   setCreateObjectUrl(url);
    //   setFileList([{ ...file, url }]);
    //   // return false;
    // },
    onChange({ file, fileList: newFileList }) {
      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            url: file.response?.image, // this would be optional
          }];
        }

        form.setFieldsValue({ image: file.response?.image });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        const values = {
          ...inputData,
          image: images ? images[0] : null,
        }

        mutateBanner({
          resource: `banners`, values, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "banners", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`이미지: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ image: null });
          setFileList([]);
        } catch (e) {
          console.error('removing from S3 failed.')
        }
      }
    }
  }

  // this is a quick and dirty solution for mobileImage
  const mobileUploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: mobileFileList,
    action: `${apiUrl}/banners/${record?.id}/mobile-image`,
    headers: headers,
    // beforeUpload: (file, files) => {
    //   //setMobileImageFile(file as RcFile);
    //   if (createObjectUrl) {
    //     URL.revokeObjectURL(createObjectUrl);
    //   }
    //   const url = URL.createObjectURL(file);
    //   setCreateObjectUrl(url);
    //   setMobileFileList([{ ...file, url }]);
    //   // return false;
    // },
    onChange({ file, fileList: newFileList }) {
      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            url: file.response?.mobileImage, // this would be optional
          }];
        }

        form.setFieldsValue({ mobileImage: file.response?.mobileImage });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        const values = {
          ...inputData,
          mobileImage: images ? images[0] : null,
        }

        mutateBanner({
          resource: `banners`, values, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "banners", invalidates: ["detail"] })
          },
        })
      }
      setMobileFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {

        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`이미지: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ mobileImage: null });
          setMobileFileList([]);
        } catch (e) {
          console.error('removing from S3 failed.')
        }
      }
    }
  }

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  const onClickStartTimeOk = (val: dayjs.Dayjs) => {
    const day = val
      .clone()
      .set('second', 0)
      .set('millisecond', 0); // get rid of jitter
    setStartedAt(day);
    form.setFieldsValue({ startedAt: day });
  }

  const onClickEndTimeOk = (val: dayjs.Dayjs) => {
    const day = val
      .clone()
      .set('seconds', 0)
      .set('milliseconds', 0); // get rid of jitter
    setEndedAt(day);
    form.setFieldsValue({ endedAt: day });
  }

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    if (record) {
      setBannerType(record.bannerType);
    }
  }, [record]);

  useEffect(() => {
    const savedUri = localStorage.getItem('banners-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    if (record?.image) {
      const filez = [record?.image].map((v: string) => {
        const filename = v.replace(/^.*[\\/]/, '');
        const extention = v.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
        return {
          uid: -10,
          name: filename,
          status: 'done',
          type: `image/${extention}`,
          url: v,
        } as unknown as UploadFile;
      })
      setFileList(filez);
    }
    if (record?.mobileImage) {
      const filez = [record?.mobileImage].map((v: string) => {
        const filename = v.replace(/^.*[\\/]/, '');
        const extention = v.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
        return {
          uid: -20,
          name: filename,
          status: 'done',
          type: `image/${extention}`,
          url: v,
        } as unknown as UploadFile;
      })
      setMobileFileList(filez);
    }
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button icon={<EyeOutlined />} onClick={(): void => goto("banners", record?.id!)}>Show</Button>
          {defaultButtons}
        </>
      )}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form
        form={form}
        {...formProps}
        onValuesChange={(values) => {
          if (values.hasOwnProperty('bannerType')) {
            setBannerType(values.bannerType);
          }
          if (values.hasOwnProperty('image')) {
            values['image'] = values['image'] ?? '';
          }
        }}
        onFinish={(values: any) => {
          const vals = {
            ...values,
            image: values['image'] ?? null,
            mobileImage: values['mobileImage'] ?? null,
          };
          formProps.onFinish && formProps.onFinish({...vals});
          // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
          setTimeout(() => {
            if (uri.length > 0) {
              push(`../banners?${uri}`);
            } else {
              goBack();
            }
          }, 500)
        }}
        layout="vertical"
      >
        <Card type="inner" title="배너 정보">
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="배너 종류"
                name="bannerType"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { label: 'POPUP (팝업배너)', value: 'popup' }, 
                    { label: 'HERO (가로형)', value: 'hero' },
                  ]}
                  value={bannerType}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="제목"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="이동 타겟"
                name="target"
                rules={[{required: false}]}
              >
                <Select
                  options={[
                    { label: '이동 없음', value: null, },
                    { label: '기획전', value: 'packs', },
                    { label: '옥션', value: 'auctions', },
                    { label: '아티클', value: 'articles', },
                    { label: '이벤트', value: 'events', },
                    { label: '작가/리셀러', value: 'artists', },
                    { label: '사용자', value: 'users', },
                    { label: '마이페이지', value: 'mypage', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="이동 타겟 아이디"
                name="targetId"
                rules={[{required: false}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="시작시각"
                name="startedAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={'YYYY-MM-DD HH:mm'}
                  showTime
                  onOk={onClickStartTimeOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="종료시각"
                name="endedAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  format={'YYYY-MM-DD HH:mm'}
                  showTime
                  onOk={onClickEndTimeOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="활성상태"
                name="isActive"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  options={[
                    { label: '활성', value: true },
                    { label: '비활성', value: false }, 
                  ]}
                  value={record?.isActive}
                />
              </Form.Item>
            </Col>
            {
              bannerType === 'popup' &&
              <Col span={6}>
                <Form.Item
                  label="버튼 레이블"
                  name="buttonLabel"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
            }
            {
              bannerType !== 'popup' &&
              <Col span={6}>
                <Form.Item
                  label="배경색"
                  name="color"
                  rules={[{required: true}]}
                >
                  <Input placeholder='#000000 와 같이 #을 포함시키세요.' />
                </Form.Item>
              </Col>
            }
            <Col span={6}>
              <Form.Item label="사진 업로드">
                <Form.Item
                  name="image"
                  valuePropName="[fileList]"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                <Upload { ...uploadProps }>
                  {fileList.length < 1 && '+ Upload'}
                </Upload>
                </Form.Item>
              </Form.Item>
            </Col>
            {
              ((record?.bannerType === 'hero' && bannerType === '') || bannerType === 'hero') &&
              <Col span={6}>
                <Form.Item label="모바일사진 업로드">
                  <Form.Item
                    name="mobileImage"
                    valuePropName="[fileList]"
                    getValueFromEvent={getValueFromEvent}
                    noStyle
                  >
                  <Upload { ...mobileUploadProps }>
                    {mobileFileList.length < 1 && '+ Upload'}
                  </Upload>
                  </Form.Item>
                </Form.Item>
              </Col>
            }
          </Row>
          <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handlePreviewModalClose}>
            <img alt="banner" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Card>
      </Form>
    </Edit>
  );
};
