import { BarcodeOutlined } from '@ant-design/icons';
import {
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  NumberField,
  ShowButton,
  Space,
  Table,
  Tag,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dayjs from 'dayjs';
import { couponTargetName } from 'helpers/coupon';
import { ICoupon } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const CouponList: React.FC<IResourceComponentsProps> = () => {

  const now = (new Date()).getTime();

  const { tableProps, sorter } = useTable<ICoupon>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={(tableProps?.dataSource as any)?.data}
      >
        <Table.Column<ICoupon>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<ICoupon>
          dataIndex="name"
          key="name"
          title="쿠폰명"
          render={(_, record) => (<Link to={`/coupons/show/${record?.id}`}>
            <BarcodeOutlined style={{ marginRight: 8 }} />
            <TextField value={record?.name} />
          </Link>)}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column<ICoupon>
          dataIndex="code"
          key="code"
          title="쿠폰코드"
          render={(_, record) => {
            return now < Date.parse(record?.expiredAt!) ?
            <TextField strong value={record.code} /> :
            <TextField delete value={record.code} />
          }}
        />
        <Table.Column<ICoupon>
          dataIndex="discount"
          key="discount"
          title="할인금액"
          render={(_, record) => <TextField value={`${record?.discount.toLocaleString()}원`} />}
          defaultSortOrder={getDefaultSortOrder("discount", sorter)}
          sorter
        />
        <Table.Column<ICoupon>
          dataIndex="target"
          key="target"
          title="쿠폰타겟"
          render={(value) => <TextField value={couponTargetName(value)} />}
          defaultSortOrder={getDefaultSortOrder("target", sorter)}
          sorter
        />
        <Table.Column<ICoupon>
          dataIndex="grantCount"
          key="grantCount"
          title="사용/제공횟수"
          render={(value) => <NumberField value={value.toLocaleString()} />}
          defaultSortOrder={getDefaultSortOrder("grantCount", sorter)}
          sorter
        />
        <Table.Column<ICoupon>
          dataIndex="expiredAt"
          key="expiredAt"
          title="만기일"
          render={(_, record) => {
            const now = (new Date()).getTime();
            const exp = Date.parse(record.expiredAt);

            return now < exp
              ? (<div key={record.expiredAt}>
                <div>🟢 <ReactTimeAgo date={Date.parse(record.expiredAt)} locale="ko" /></div>
                <div><TextField style={{fontSize: 9}} value={dayjs(record.expiredAt).format('YY-MM-DD HH:mm:ss')} /></div>
              </div>)
              : (<div key={record.expiredAt}>
              <div>🔴 <ReactTimeAgo date={Date.parse(record.expiredAt)} locale="ko" /></div>
              <div><TextField style={{fontSize: 9}} value={dayjs(record.expiredAt).format('YY-MM-DD HH:mm:ss')} /></div>
            </div>)
          }
        }
          defaultSortOrder={getDefaultSortOrder("expiredAt", sorter)}
          sorter
        />
        <Table.Column<ICoupon>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
