export const packTypeName = (e: string): string => {
  if (e === `auctions`) {
    return '옥션판매';
  }
  if (e === `buyItNows`) {
    return '일반판매';
  }

  return '기타'
}
