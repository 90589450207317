import { DeleteOutlined, EyeOutlined, InboxOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Edit,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  RcFile,
  Row,
  Select,
  Tag,
  Typography,
  Upload,
  UploadFile,
  getValueFromEvent,
  message,
  useForm,
  useModal
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useInvalidate, useList, useNavigation, useUpdate } from "@pankod/refine-core";
import type { UploadProps } from 'antd';
import { articleCategoryColor, articleCategoryName } from 'helpers/article';
import { firstImageUrl } from 'helpers/artwork';
import { sellerTypeColor, sellerTypeName } from 'helpers/seller';
import { avatarUrl } from 'helpers/user';
import { IArticle, IAuction } from 'interfaces';
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useMediaQuery } from 'react-responsive';
import { ACCESS_TOKEN } from "../../constants";
const { Text } = Typography;

const radioOptions = [
  { label: '노출', value: true },
  { label: '숨김', value: false }, 
];

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const ArticleEdit: React.FC<IResourceComponentsProps> = () => {
  // useMediaQuery hook
  const isBigScreen = useMediaQuery({ minWidth: 1280 })
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 })

  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useUpdate hook
  const { mutate: mutateArticle } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IArticle>({
    redirect: false,
  });

  // useState hooks
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");
  const [uri, setUri] = useState('');
  
  const [auctionSearch, setAuctionSearch] = useState<string>('')
  const [fetchedAuctions, setFetchedAuctions] = useState<IAuction[]>([])
  const [selectedAuctions, setSelectedAuctions] = useState<IAuction[]>([])
  const [selectedAuctionIds, setSelectedAuctionIds] = useState<number[]>([])
  const [currentAuctionIds, setCurrentAuctionIds] = useState<number[]>([])
  const [articleSearch, setArticleSearch] = useState<string>('')
  const [fetchedArticles, setFetchedArticles] = useState<IArticle[]>([])
  const [selectedArticles, setSelectedArticles] = useState<IArticle[]>([])
  const [selectedArticleIds, setSelectedArticleIds] = useState<number[]>([])
  const [currentArticleIds, setCurrentArticleIds] = useState<number[]>([])

  // constant definition to be used here
  const record = queryResult?.data?.data as IArticle;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  // useModal hook
  const { modalProps: auctionModalProps, show: showAuctionModal, close: closeAuctionModal } = useModal()
  const { modalProps: articleModalProps, show: showArticleModal, close: closeArticleModal } = useModal()

  //?-------------------------------------------------------------------------//
  //? file upload
  //?-------------------------------------------------------------------------//

  const draggerProps: UploadProps = { // file 하나씩 업로드
    name: 'file',
    listType: 'picture',
    fileList: fileList,
    multiple: true,
    action: `${apiUrl}/articles/${record?.id}/image`,
    headers: headers,
    async onChange(info) {
      const newFileList =
        await Promise.all([...info.fileList].map(async(file) => {
          if (file.response) {
            file.url = await file.response.data;
          }
          return file;
        }));
      const images = newFileList.filter((v) => v.url).map((v) => v.url);
      const inputData = form.getFieldsValue() as any;
      inputData.images = images;
      if (images.filter((v) => v).length === newFileList.length) {
        mutateArticle({
          resource: `articles`,
          values: inputData,
          id: record.id
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "auctions", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`작품명: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const src = file.url as string;
      if (src) {
        const newFileList = fileList.filter((e) => e.url !== src);
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: src.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
        } catch (e) {
          console.error(e, 'removing from S3 failed.')
        }
        setFileList(newFileList);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const transformStringsToUploadFiles = (items: string[]): UploadFile[] => {
    return items.map((item: string, i: number) => {
      const filename = item.replace(/^.*[\\/]/, '');
      const extention = item.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
      return {
        uid: `-${i+1}`,
        name: filename,
        status: 'done',
        type: `image/${extention}`,
        url: item,
      } as UploadFile
    })
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });

  //?-------------------------------------------------------------------------//
  //? Auction Modal
  //?-------------------------------------------------------------------------//

  const onAuctionModalOpen = () => {
    setAuctionSearch('')
    setFetchedArticles([]);
    showAuctionModal();
  }

  const onAuctionModalClose= (values: any) => {
    const newIds = currentAuctionIds;
    const oldIds = record?.auctions?.map((v) => v.id) ?? [];
    const fetchedIds = fetchedAuctions.map((v) => v.id)
    const diff = oldIds.filter((v) => !fetchedIds.includes(v));
    const mergedIds = [...newIds, ...diff];
    const uniqueIds = mergedIds.filter((v, i) => mergedIds.indexOf(v) === i);
    setSelectedAuctionIds(uniqueIds);
    closeAuctionModal()
    message.info(`화면 하단의 Save 버튼을 눌러야만, DB에 변경사항이 저장됩니다.`);
  }

  const onAuctionDelete = (i: number) => {
    const updatedAuctions = selectedAuctions.filter((v) => v.id !== i)
    setSelectedAuctions(updatedAuctions);
    message.info(`화면 하단의 Save 버튼을 눌러야만, DB에 변경사항이 저장됩니다.`);
  }

  const { refetch: refetchSearchAuction } = useList<IAuction>({
    resource: 'auctions',
    config: {
      filters: [
        // { field: 'title', operator: 'contains', value: auctionSearch },
        { field: 'artistName', operator: 'contains', value: auctionSearch },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const auctions = ((data?.data as any)?.data as IAuction[]);
        setFetchedAuctions(auctions);
      },
    },
  })

  const { refetch: refetchWithAuctionIds } = useList<IAuction>({
    resource: 'auctions',
    config: {
      filters: [
        { field: 'id', operator: 'in', value: selectedAuctionIds },
      ],
    },
    queryOptions: {
      enabled: selectedAuctionIds.length > 0,
      onSuccess: (data) => {
        const auctions = ((data?.data as any)?.data as IAuction[]);
        setSelectedAuctions(auctions);
      },
    },
  })

  //?-------------------------------------------------------------------------//
  //? Article Modal
  //?-------------------------------------------------------------------------//

  const onArticleModalOpen = () => {
    setArticleSearch('');
    setFetchedArticles([]);
    showArticleModal();
  }

  const onArticleModalClose = () => {
    const newIds = currentArticleIds;
    const oldIds = record?.relatedArticles?.map((v) => v.id) ?? [];
    const fetchedIds = fetchedArticles.map((v) => v.id)
    const diff = oldIds.filter((v) => !fetchedIds.includes(v));
    const mergedIds = [...newIds, ...diff];
    const uniqueIds = mergedIds.filter((v, i) => mergedIds.indexOf(v) === i);
    setSelectedArticleIds(uniqueIds);
    closeArticleModal()
    message.info(`화면 하단의 Save 버튼을 눌러야만, DB에 변경사항이 저장됩니다.`);
  }

  const onArticleDelete = (i: number) => {
    const updatedArticles = selectedArticles.filter((v) => v.id !== i)
    setSelectedArticles(updatedArticles);
    message.info(`화면 하단의 Save 버튼을 눌러야만, DB에 변경사항이 저장됩니다.`);
  }

  const { refetch: refetchSearchArticle } = useList<IArticle>({
    resource: 'articles',
    config: {
      filters: [
        { field: 'search', operator: 'contains', value: articleSearch },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const articles = ((data?.data as any)?.data as IArticle[])
          .filter((v) => v.id !== record?.id); // exclude itself.
        console.log(articles, '<~ fetched articles');
        setFetchedArticles(articles);
      },
    },
  })

  const { refetch: refetchWithArticleIds } = useList<IArticle>({
    resource: 'articles',
    config: {
      filters: [
        { field: 'id', operator: 'in', value: selectedArticleIds },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const articles = ((data?.data as any)?.data as IArticle[]);
        setSelectedArticles(articles);
      },
    },
  })

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  //?-------------------------------------------------------------------------//
  //? useEffect hooks
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('articles-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    console.log(`[useEffect hook] <IArticle> data has been changed. => ${record}`)
    if (record?.images && record?.images.length > 0) {
      const imageList: UploadFile[] = transformStringsToUploadFiles(record?.images);
      setFileList(imageList);
    }
    if (record?.auctions && record?.auctions.length > 0) {
      setSelectedAuctions(record.auctions);
    }
    if (record?.relatedArticles && record?.relatedArticles.length > 0) {
      setSelectedArticles(record.relatedArticles);
    }
  }, [record]);

  // useEffect(() => {
  //   if (selectedAuctionIds.length > 0) {
  //     refetchWithAuctionIds()
  //   }
  // }, [selectedAuctionIds])

  useEffect(() => {
    setFetchedAuctions([]);
    if (auctionSearch) {
      refetchSearchAuction();
    }
  }, [auctionSearch])

  useEffect(() => {
    if (selectedArticleIds.length > 0) {
      refetchWithArticleIds()
    }
  }, [selectedArticleIds])

  useEffect(() => {
    setFetchedArticles([]);
    if (articleSearch) {
      refetchSearchArticle();
    }
  }, [articleSearch])

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <>
      <Edit
        isLoading={queryResult?.isLoading}
        saveButtonProps={saveButtonProps}
        headerButtons={({ defaultButtons }) => (
          <>
            <Button
              icon={<EyeOutlined />}
              onClick={(): void => goto("articles", record?.id!)}
            >Show</Button>
            {defaultButtons}
          </>
        )}
        contentProps={{
          style: {
            backgroundColor: "#f0f2f5",
          },
        }}
      >
        <Form {...formProps}
          form={form}
          onFinish={(values) => {
            formProps.onFinish && formProps.onFinish({
              ...values,
              images: fileList.map((e) => e.url),
              auctionIds: selectedAuctions.map((v) => v.id) ?? [],
              articleIds: selectedArticles.map((v) => v.id) ?? [],
            });
            // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
            setTimeout(() => {
              if (uri.length > 0) {
                push(`../articles?${uri}`);
              } else {
                goBack();
              }
            }, 500)
          }}
          layout="vertical"
        >
          <Card
            type="inner"
            title={`관련 옥션 (${selectedAuctions.length}개)`}
            extra={
              <Button size="small" icon={<UserOutlined />} onClick={onAuctionModalOpen}>
                옥션 선택
              </Button>
            }
            style={{marginBottom: 24}}
          >
            <Row gutter={[16, 16]}>
            {
              selectedAuctions && selectedAuctions.length > 0
              ? selectedAuctions!.map((auction, i) => {
                return (
                  <Col key={`article-auction-col-${i}`} span={isBigScreen? 3 : (isSmallScreen? 6 : 4)}>
                    <Card
                      key={`article-auction-key-${i}`} 
                      bordered={true}
                      size="small"
                      style={{ padding: 2, border: '1px solid #ccc' }}
                      cover={
                        <img src={auction.images![0]}
                          alt={auction.title}
                          style={{ height: 160 }} />
                      }
                    >
                      <div style={{display: 'flex', minWidth: 0, alignItems: 'center'}}>
                        {
                          auction && auction.artwork && auction.artwork.artist && auction.artwork.artist.user &&
                          <Avatar
                            size={32}
                            src={avatarUrl(auction.artwork?.artist?.user?.avatar)}
                            style={{marginRight: 8}}
                          />
                        }
                        <Text ellipsis style={{ fontSize: 11 }}>{ auction.artwork?.artist?.name }</Text>
                      </div>
                      <Button
                        size='small'
                        danger
                        icon={<DeleteOutlined />}
                        style={{ position: 'absolute', top: 124, right: 10 }}
                        onClick={() => onAuctionDelete(auction.id)} />
                      {
                        auction && auction.artwork && auction.artwork.artist &&
                        <Tag
                          color={sellerTypeColor(auction.artwork?.artist?.sellerType)}
                          style={{ fontSize: 10, position: 'absolute', top: 124, left: 10 }}
                        >
                          {sellerTypeName(auction.artwork?.artist?.sellerType)}
                        </Tag>
                      }
                      <Tag
                        style={{ fontSize: 10, position: 'absolute', top: 10, left: 10 }}
                      >
                        { auction.artwork?.title }
                      </Tag>
                    </Card>
                  </Col>
                );
              }) : <Col span={24}><Text>관련 옥션정보가 없습니다. 옥션 선택 버튼을 누르세요.</Text></Col>
            }
            </Row>
          </Card>
          <Card
            type="inner"
            title={`관련 아티클 (${selectedArticles.length}개)`}
            extra={
              <Button size="small" icon={<UserOutlined />} onClick={onArticleModalOpen}>
                아티클 선택
              </Button>
            }
            style={{marginBottom: 24}}
          >
            <Row gutter={[16, 16]}>
            {
              selectedArticles && selectedArticles.length > 0
              ? selectedArticles!.map((article, i) => {
                return (
                  <Col key={`article-article-col-${i}`} span={isBigScreen? 3 : (isSmallScreen? 6 : 4)}>
                    <Card
                      key={`article-article-key-${i}`} 
                      bordered={true}
                      size="small"
                      style={{ padding: 2, border: '1px solid #ccc' }}
                      cover={
                        <img src={article.images![0]}
                          alt={article.title}
                          style={{ height: 160 }} />
                      }
                    >
                      <Text ellipsis style={{ fontSize: 11 }}>{ article.subtitle }</Text>
                      <Button
                        size='small'
                        danger
                        icon={<DeleteOutlined />}
                        style={{ position: 'absolute', top: 124, right: 10 }}
                        onClick={() => onArticleDelete(article.id)} />
                      <Tag
                        color={articleCategoryColor(article.category)}
                        style={{ fontSize: 10, position: 'absolute', top: 124, left: 10 }}
                      >
                        {articleCategoryName(article.category)}
                      </Tag>
                      <Tag
                        style={{ fontSize: 10, position: 'absolute', top: 10, left: 10 }}
                      >
                        { article.title }
                      </Tag>
                    </Card>
                  </Col>
                );
              }) : <Col span={24}><Text>관련 아티클 정보가 없습니다. 아티클 선택 버튼을 누르세요.</Text></Col>
            }
            </Row>
          </Card>
          <Card type="inner" title="작품정보" style={{marginBottom: 24}}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="제목"
                  name="title"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="부제목"
                  name="subtitle"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="분류"
                  name="category"
                >
                  <Select
                    options={[
                      { label: '인터뷰', value: 'INTERVIEW', },
                      { label: '큐레이션', value: 'CURATION', },
                      { label: '플리크루', value: 'FLEA_CREW', },
                      { label: '플리옥션', value: 'FLEA_AUCTION', },
                      { label: '서비스', value: 'SERVICE', },
                      { label: '이벤트', value: 'EVENT', },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="활성상태"
                  name="isPublished"
                >
                  <Radio.Group
                    options={radioOptions}
                    value={record?.isPublished}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="이미지 업로드">
                  <Form.Item
                    name="images"
                    valuePropName="fileList"
                    getValueProps={ (value) => value }
                    getValueFromEvent={getValueFromEvent}
                    noStyle
                  >
                    <Upload.Dragger { ...draggerProps }>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: 14 }}
                      >
                        이미지 파일을 이곳으로 Drag & Drop 하세요.
                      </p>
                    </Upload.Dragger>
                    <Modal
                      visible={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handlePreviewModalClose}
                    >
                      <img alt="avatar" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="내용"
                  name="body"
                >
                  <ReactMde
                    selectedTab={selectTab}
                    onTabChange={setSelectTab}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Edit>
      <Modal
        key="auctionSearch"
        {...auctionModalProps}
        title="옥션 선택"
        destroyOnClose={true}
        onOk={onAuctionModalClose}
        width={800}
      >
        <Card style={{marginBottom: 16}}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <AutoComplete
                style={{ width: '100%' }}
                filterOption={true}
                onChange={(v: string) => setAuctionSearch(v)}
                //onSearch={debounce((v: string) => setAuctionSearch(v), 500)}
              >
                <Input.Search allowClear placeholder="제목 또는 작가명으로 검색" />
              </AutoComplete>
            </Col>
          </Row>
        </Card>
        <Card
          type="inner"
          title="옥션 리스트"
          extra="연결할 옥션을 선택하세요."
        >
          <Checkbox.Group
            defaultValue={selectedAuctions.map((v) => v.id)}
            onChange={(v) => setCurrentAuctionIds([...v as number[]])}
          >
          {
            fetchedAuctions && fetchedAuctions.length > 0
            ? fetchedAuctions!.map((auction) => {
              return (<Row
                key={`artwork-row-${auction.id}`}
                gutter={[16, 16]}>
                <Col span={24}>
                  <Image
                    src={firstImageUrl(auction.images)}
                    width={64}
                    height={64}
                  />
                  <Checkbox
                    value={auction.id}
                    style={{ marginLeft: 10, alignItems: 'center' }}
                  >
                    <div>
                      <div>
                        <Tag style={{ marginRight: 8 }}>작품명</Tag>
                        <Text>{auction.title}</Text></div>
                      <div>
                        <Tag style={{ marginRight: 8 }}>작가명</Tag>
                        <Text>{auction.artistName}</Text>
                      </div>
                    </div>
                  </Checkbox>  
                </Col>
              </Row>)
            })
            : <Row gutter={[16, 16]}>
              <Col span={24}><Tag>정보없음</Tag></Col>
            </Row>
          }
          </Checkbox.Group>
        </Card>
      </Modal>
      <Modal
        key="articleSearch"
        {...articleModalProps}
        title="아티클 선택"
        destroyOnClose={true}
        onOk={onArticleModalClose}
        width={800}
      >
        <Card style={{marginBottom: 16}}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <AutoComplete
                style={{ width: '100%' }}
                filterOption={true}
                onChange={(v: string) => setArticleSearch(v)}
                //onSearch={debounce((v: string) => setArticleSearch(v), 500)}
              >
                <Input.Search allowClear placeholder="제목 또는 부제목으로 검색" />
              </AutoComplete>
            </Col>
          </Row>
        </Card>
        <Card
          type="inner"
          title="아티클 리스트"
          extra="연결할 아티클을 선택하세요."
        >
          <Checkbox.Group
            defaultValue={selectedArticles.map((v) => v.id)}
            onChange={(v) => setCurrentArticleIds([...v as number[]])}
          >
          {
            fetchedArticles && fetchedArticles.length > 0
            ? fetchedArticles!.map((article) => {
              return (<Row
                key={`artwork-row-${article.id}`}
                gutter={[16, 16]}>
                <Col span={24}>
                  <Image
                    src={firstImageUrl(article.images)}
                    width={64}
                    height={64}
                  />
                  <Checkbox
                    value={article.id}
                    style={{ marginLeft: 10, alignItems: 'center' }}
                  >
                    <div>
                      <div>
                        <Tag style={{ marginRight: 8 }}>제 목</Tag>
                        <Text>{article.title}</Text></div>
                      <div>
                        <Tag style={{ marginRight: 8 }}>부제목</Tag>
                        <Text>{article.subtitle}</Text>
                      </div>
                    </div>
                  </Checkbox>  
                </Col>
              </Row>)
            })
            : <Row gutter={[16, 16]}>
              <Col span={24}><Tag>정보없음</Tag></Col>
            </Row>
          }
          </Checkbox.Group>
        </Card>
      </Modal>
    </>
  );
};
