import { DeleteOutlined, EyeOutlined, FileOutlined, InboxOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  RcFile,
  Row,
  Select,
  Tag,
  Typography,
  Upload,
  UploadFile,
  getValueFromEvent,
  useForm,
  useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useDelete, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import type { UploadProps } from 'antd';
import dayjs from "dayjs";
import { articleCategoryColor, articleCategoryName } from 'helpers/article';
import { IArticle, IAuction } from "interfaces";
import { SelectArticleModal } from 'pages/auctions/components/article/select-article-modal';
import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import "react-mde/lib/styles/css/react-mde-all.css";
import { useMediaQuery } from 'react-responsive';
import { ACCESS_TOKEN } from "../../constants";
import { getBase64 } from 'helpers/utils';
const { Text } = Typography;

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const AuctionEdit: React.FC<IResourceComponentsProps> = () => {
  // useMediaQuery hook
  const isBigScreen = useMediaQuery({ minWidth: 1280 })
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 })

  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useDelete hook
  const { mutate: mutateArticle } = useDelete();

  // useUpdate hook
  const { mutate: mutateAuction } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IAuction>({
    redirect: false,
  });

  // useState hooks
  const [isShippable, setIsShippable] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");
  const [uri, setUri] = useState('');

  // more constants
  const record = queryResult?.data?.data as IAuction;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  // useModalForm hook
  const { formProps: articleFormProps, modalProps: articleModalProps, show } = useModalForm({
    action: "create",
    resource: `auctions/${record?.id}/articles`,
    redirect: false,
    onMutationSuccess: () => {
      invalidate({ resource: "auctions", invalidates: ["detail"] })
      queryResult?.refetch()
    },
  });

  //?-------------------------------------------------------------------------//
  //? file upload
  //?-------------------------------------------------------------------------//

  const draggerProps: UploadProps = {
    name: 'file',
    listType: 'picture',
    fileList: fileList,
    multiple: true,
    action: `${apiUrl}/auctions/${record?.id}/image`,
    headers: headers,
    async onChange(info) {
      const newFileList =
        await Promise.all([...info.fileList].map(async(file) => {
          if (file.response) {
            file.url = await file.response.data;
          }
          return file;
        }));
      const images = newFileList.filter((v) => v.url).map((v) => v.url);
      const inputData = form.getFieldsValue() as any;
      inputData.images = images;
      if (images.filter((v) => v).length === newFileList.length) {
        mutateAuction({
          resource: `auctions`,
          values: inputData,
          id: record.id
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "auctions", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`작품명: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const src = file.url as string;
      if (src) {
        const newFileList = fileList.filter((e) => e.url !== src);
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: src.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
        } catch (e) {
          console.error(e, 'removing image(s) from S3 failed.')
        }
        setFileList(newFileList);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const transformStringsToUploadFiles = (items: string[]): UploadFile[] => {
    return items.map((item: string, i: number) => {
      const filename = item.replace(/^.*[\\/]/, '');
      const extention = item.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
      return {
        uid: `-${i+1}`,
        name: filename,
        status: 'done',
        type: `image/${extention}`,
        url: item,
      } as UploadFile
    })
  }


  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  const onStartTimePickerOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ startTime: day });
    return day;
  }

  const onEndTimePickerOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ endTime: day });
    return day;
  }

  const onClosingTimePickerOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ closingTime: day });
    return day;
  }

  const onScheduledAtPickerOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ scheduledAt: day });
    return day;
  }

  const onArticleDelete = async (id: number) => {
    mutateArticle({
      resource: `auctions/${record?.id}/articles`,
      id
    }, 
    {
      onSuccess: (data, variables, context) => {
        invalidate({ resource: "auctions", invalidates: ["detail"] })
        queryResult?.refetch()
      },
    })
  }

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//
  
  useEffect(() => {
    const savedUri = localStorage.getItem('auctions-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    console.log(`[useEffect hook] <IAuction> data has been changed. => ${record}`)
    // if images property exists
    if (record?.images && record?.images.length > 0) {
      const imageList: UploadFile[] = transformStringsToUploadFiles(record?.images);
      setFileList(imageList);
    }
    setIsShippable(record?.isShippable);
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList (as opposed to the raw data.)
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<EyeOutlined />}
            onClick={(): void => goto("auctions", record?.id!)}
          >Show</Button>
          {defaultButtons}
        </>
      )}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <SelectArticleModal modalProps={ articleModalProps } formProps={ articleFormProps } />
      <Form {...formProps} form={form} onFinish={(values: any) =>
        {
          console.log(`formProps => `, formProps);
          console.log(values);
          formProps.onFinish && formProps.onFinish({
            ...values, images: fileList.map((e) => e.url),
          });
          // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
          setTimeout(() => {
            if (uri.length > 0) {
              push(`../auctions?${uri}`);
            } else {
              goBack();
            }
          }, 500)
        }} layout="vertical"
      >
        <Card
          type="inner"
          title="관련 아티클"
          extra={
            <Button size="small" icon={<FileOutlined />} onClick={() => show()}>
              아티클 선택
            </Button>
          }
          style={{marginBottom: 24}}
        >
          <Row gutter={[16, 16]}>
            {
              record?.articles && record?.articles.length > 0
              ? record?.articles!.sort((a: IArticle, b: IArticle) => a.id > b.id ? -1 : 1).map((article, i) => {
                return (
                  <Col key={`article-col-${i}`} span={isBigScreen? 3 : (isSmallScreen? 6 : 4)}>
                    <Card
                      key={`article-key-${i}`} 
                      bordered={true}
                      size="small"
                      style={{ padding: 2, border: '1px solid #ccc' }}
                      cover={
                        <img src={article.images![0]}
                          alt={article.title}
                          style={{ height: 160 }} />
                      }
                    >
                      <Text ellipsis style={{ fontSize: 11}}>{ article.subtitle }</Text>
                      <Button
                        size='small'
                        danger
                        icon={<DeleteOutlined />}
                        style={{ position: 'absolute', top: 124, right: 10 }}
                        onClick={() => onArticleDelete(article.id)} />
                      <Tag
                        color={articleCategoryColor(article.category)}
                        style={{ fontSize: 10, position: 'absolute', top: 124, left: 10 }}
                      >
                        {articleCategoryName(article.category)}
                      </Tag>
                      <Tag
                        style={{ fontSize: 10, position: 'absolute', top: 10, left: 10 }}
                      >
                        { article.title }
                      </Tag>
                    </Card>
                  </Col>
                );
              }) : <Col span={24}><Text>관련 아티클 정보가 없습니다. 아티클 선택 버튼을 누르세요.</Text></Col>
            }
          </Row>
        </Card>
        <Card type="inner" title="작품정보">
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="옥션 제목"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="옥션 부제 (기획전 제목)"
                name="subtitle"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="작가명"
                name="artistName"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="경매 진행주차"
                name="weeks"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="시작시각"
                name="startTime"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onStartTimePickerOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="종료시각"
                name="endTime"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onEndTimePickerOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="최종종료시각"
                name="closingTime"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onClosingTimePickerOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="택배발송 예정시각"
                name="scheduledAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onScheduledAtPickerOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="추정가"
                name="estimate"
                rules={[{required: false}]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="즉시구매가"
                name="buyItNowPrice"
                rules={[{required: false}]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="최소구매가"
                name="reservePrice"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="경매시작가"
                name="startingPrice"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="호가"
                name="bidIncrement"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="조회수"
                name="viewCount"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="북마크수"
                name="bookmarkCount"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="입찰수"
                name="bidCount"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="상태"
                name="status"
              >
                <Select
                  options={[
                    { label: '준비중', value: 'PREPARING', },
                    { label: '진행중', value: 'ONGOING', },
                    { label: '종료', value: 'ENDED', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="배송여부"
                name="isShippable"
              >
                <Radio.Group
                  options={[
                    { label: '배송비 정상계산', value: true, },
                    { label: '배송비 0원', value: false, },
                  ]}
                  value={record?.isShippable ? true : false}
                  onChange={(e) => {
                    setIsShippable(e.target.value)
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              {
                isShippable &&
                <Form.Item
                  label="묶음배송"
                  name="isCombinable"
                >
                  <Radio.Group
                    options={[
                      { label: '가능', value: true, },
                      { label: '불가', value: false, },
                    ]}
                    value={record?.isCombinable ? true : false}
                  />
                </Form.Item>
              }
            </Col>
            <Col span={6}>
              {
                isShippable &&
                <Form.Item
                  label="핸들링 fee"
                  name="handlingFee"
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              }
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="이미지 업로드">
                <Form.Item
                  name="images"
                  valuePropName="fileList"
                  getValueProps={ (value) => value }
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                  <Upload.Dragger { ...draggerProps }>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p
                      className="ant-upload-text"
                      style={{ fontSize: 14 }}
                    >
                      이미지 파일을 이곳으로 Drag & Drop 하세요.
                    </p>
                  </Upload.Dragger>
                  <Modal
                    visible={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handlePreviewModalClose}
                  >
                    <img alt="auction" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="안내문"
                name="finePrint"
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="판매채널"
                name="channel"
              >
                <Select
                  options={[
                    { label: '플리옥션', value: null, },
                    { label: '플리옥션 글로벌', value: 'global', },
                    { label: '삼성물산 서초', value: 'seocho', },
                    { label: '삼성물산 거제', value: 'geoje', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="위치"
                name="location"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="온·오프라인 구분"
                name="online"
              >
                <Radio.Group
                  options={[
                    { label: '온라인', value: true, },
                    { label: '오프라인', value: false, },
                  ]}
                  value={record?.online ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="리셀러(오너) ID"
                name="resellerId"
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="비고"
                name="note"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Edit>
  );
};
