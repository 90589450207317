import {
  Image,
  Layout,
  notificationProvider,
  ReadyPage,
  Typography
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import { ErrorComponent, HttpError, Refine } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import axios, { AxiosRequestConfig } from "axios";
import TimeAgo from 'javascript-time-ago';
import ko from 'javascript-time-ago/locale/ko.json';
import { ArticleCreate, ArticleEdit, ArticleList, ArticleShow } from "pages/articles";
import { ArtistCreate, ArtistEdit, ArtistList, ArtistShow } from "pages/artists";
import { ArtworkCreate, ArtworkEdit, ArtworkList, ArtworkShow } from "pages/artworks";
import { AuctionEdit, AuctionList, AuctionShow } from "pages/auctions";
import { ForgotPasswordPage } from "pages/auth/forgot-password";
import { LoginPage } from "pages/auth/login";
import { UpdatePasswordPage } from "pages/auth/update-password";
import { BannerCreate, BannerEdit, BannerList, BannerShow } from "pages/banners";
import { CouponCreate, CouponEdit, CouponList, CouponShow } from "pages/coupons";
import { DashboardPage } from "pages/dashboard";
import { HashtagList } from "pages/hashtags";
import { InvoiceEdit, InvoiceList, InvoiceShow } from "pages/invoices";
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "pages/news";
import { OfferEdit, OfferList, OfferShow } from "pages/offers";
import { OrderEdit, OrderList, OrderShow } from "pages/orders";
import { PackCreate, PackEdit, PackList, PackShow } from "pages/packs";
import { EventCreate, EventEdit, EventList, EventShow } from "pages/events";
import { PaymentEdit, PaymentList, PaymentShow } from "pages/payments";
import { QuestionList } from "pages/questions";
import { ReportList, ReportShow } from "pages/reports";
import { SurveyList, SurveyShow } from "pages/surveys";
import { UserCreate, UserEdit, UserList, UserShow } from "pages/users";
import { accessControl } from "providers/access-control-provider";
import { apiDataProvider } from "providers/api-data-provider";
import { authProvider } from 'providers/auth-provider';
import { Header } from "./components/header";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./constants";

TimeAgo.addDefaultLocale(ko)

const { Text } = Typography;
const apiUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  }
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log('~~~~~~~~~~~~~');
    console.log(error.response);
    console.log('~~~~~~~~~~~~~');

    const { config, response: { status: statusCode } } = error;
    if (statusCode === 401) {
      try {
        const token = localStorage.getItem(REFRESH_TOKEN)
        if (token) {
          const { data } = await axios.post(`${apiUrl}/auth/refresh`, null, {
            headers: { Authorization: `Bearer ${token}` },
          })
          localStorage.setItem(ACCESS_TOKEN, data.accessToken)
          localStorage.setItem(REFRESH_TOKEN, data.refreshToken)
          console.log(data.accessToken, 'axios token has been changed!')
          axiosInstance.defaults.headers.common['Auhorization'] = `Bearer ${data.accessToken}`;
          return axiosInstance(config);
        }
      } catch (err) {
        console.log('silently igonored.')
        // return Promise.reject({ redirectPath: '/login' })
      }
    } else {
      const customError: HttpError = {
        message: error.response?.data?.message,
        statusCode: error.response?.data?.statusCode,
      };
      return Promise.reject(customError);
    }
  },
);

function App() {
  console.log(`${apiUrl} is current API endpoint`);
  return (
    <Refine
      Title={({ collapsed }) => (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          {!collapsed && <>
            <div><Image width={120} preview={false} src="https://cdn.fleaauction.world/svgs/logo.svg" /></div>
            <div><Text style={{ fontSize: 12 }}>플리옥션 v2 관리자</Text></div>
            </>
            }
        </div>
      )}
      Header={Header}
      Layout={Layout}
      LoginPage={LoginPage}
      ReadyPage={ReadyPage}
      DashboardPage={DashboardPage}
      catchAll={<ErrorComponent />}
      authProvider={authProvider(axiosInstance)}
      dataProvider={apiDataProvider(apiUrl!, axiosInstance)}
      accessControlProvider={accessControl}
      notificationProvider={notificationProvider}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            path: "/forgot-password",
            element: <ForgotPasswordPage
              formProps={{}}
            />,
          },
          {
            path: "/update-password",
            element: <UpdatePasswordPage
              formProps={{}}
            />,
          },
        ],
        // RouterComponent: HashRouterComponent,
      }}
      resources={[
        {
          name: "users",
          list: UserList,
          create: UserCreate,
          edit: UserEdit,
          show: UserShow,
        },
        {
          name: "articles",
          list: ArticleList,
          create: ArticleCreate,
          edit: ArticleEdit,
          show: ArticleShow,
        },
        {
          name: "artists",
          list: ArtistList,
          create: ArtistCreate,
          edit: ArtistEdit,
          show: ArtistShow,
        },
        {
          name: "artworks",
          list: ArtworkList,
          create: ArtworkCreate,
          edit: ArtworkEdit,
          show: ArtworkShow,
        },
        {
          name: "auctions",
          list: AuctionList,
          edit: AuctionEdit,
          show: AuctionShow,
        },
        {
          name: "banners",
          list: BannerList,
          create: BannerCreate,
          edit: BannerEdit,
          show: BannerShow,
        },
        {
          name: "coupons",
          list: CouponList,
          create: CouponCreate,
          edit: CouponEdit,
          show: CouponShow,
        },
        {
          name: "events",
          list: EventList,
          create: EventCreate,
          edit: EventEdit,
          show: EventShow,
        },
        {
          name: "hashtags",
          list: HashtagList,
        },
        {
          name: "news",
          list: NewsList,
          create: NewsCreate,
          edit: NewsEdit,
          show: NewsShow,
        },
        {
          name: "offers",
          list: OfferList,
          edit: OfferEdit,
          show: OfferShow,
        },
        {
          name: "orders",
          list: OrderList,
          edit: OrderEdit,
          show: OrderShow,
        },
        {
          name: "packs",
          list: PackList,
          create: PackCreate,
          edit: PackEdit,
          show: PackShow,
        },
        {
          name: "payments",
          list: PaymentList,
          edit: PaymentEdit,
          show: PaymentShow,
        },
        {
          name: "questions",
          list: QuestionList,
        },
        {
          name: "reports",
          list: ReportList,
          show: ReportShow,
        },
        {
          name: "surveys",
          list: SurveyList,
          show: SurveyShow,
        },
      ]}
      options={{
        reactQuery: {
          clientConfig: {
            defaultOptions: {
              queries: {
                retry: false,
              }
            }
          }
        },
        redirect: {
          afterCreate: "show",
          afterClone: "edit",
          afterEdit: false,
        },
      }}
    />
  );
}

export default App;
