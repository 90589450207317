import { EyeOutlined } from '@ant-design/icons';
import { CopyOutlined } from '@ant-design/icons';
import { S3Client } from "@aws-sdk/client-s3";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Edit,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  RcFile,
  Row,
  Select,
  Tag,
  UploadFile,
  getValueFromEvent,
  message,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCustomMutation, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import type { UploadProps } from 'antd';
import { categoryName, genreName, orientationImageUrl } from 'helpers/artwork';
import routerProvider from "@pankod/refine-react-router-v6";
import { IArtwork } from "interfaces";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import Resizer from "react-image-file-resizer";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ACCESS_TOKEN } from "../../constants";
import ImageUploader from 'pages/artworks/components/image-uploader';


const { useParams } = routerProvider;

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const ArtworkEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  const params = useParams();
  const invalidate = useInvalidate();
  // useUpdate hook
  const { mutate: mutateArtwork } = useUpdate();
  const { mutate: customMutate } = useCustomMutation();

  const copyImages = () => {

    const images = uploadFileList.filter((v) => v != null).map((e) => e.url);
    console.log(images);

    customMutate({
      url: `${process.env.REACT_APP_API_URL}/artworks/${params.id}/image/copy`,
      method: "post",
      values: {
        images,
      },
    },
    {
      onError: (error, variables, context) => {
        // An error occurred!
      },
      onSuccess: (data, variables, context) => {
        const a = data.data;
        console.log(a);
        message.success('이미지를 관련 auction 에 복사했습니다.')
        // const { upload, image } = data.data;
        // callback(upload, image);
      },
    });
  }

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IArtwork>({
    redirect: false,
  });
  
  // useState hooks
  const [uploadFileList, setUploadFileList] = useState<UploadFile<string | File | Blob | ProgressEvent<FileReader>>[]>([]);
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");
  const [returnUri, setReturnUri] = useState('');
  const [trimmedBody, setTrimmedBody] = useState('');
  const [bg, setBg] = useState('nil');
  const [manualColor, setManualColor] = useState<string>('');
  const [needToCopy, setNeedToCopy] = useState<boolean>(false);
  // more constants
  const record = queryResult?.data?.data as IArtwork;

  const convertUrlsToUploadFiles = (urls: string[]): UploadFile[] => {
    return urls.filter((v) => v).map((url: string, i: number) => {
      const filename = url.replace(/^.*[\\/]/, '');
      const extention = url.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
      return {
        uid: `-${i+1}`,
        name: filename,
        status: 'done',
        type: `image/${extention}`,
        url: url,
      } as UploadFile
    })
  }

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('artworks-list-uri');
    if (savedUri) {
      setReturnUri(savedUri);
    }
  }, []);

  useEffect(() => {
    if (record) {

      if (record.body) {
        setTrimmedBody(record.body.substring(0, record.body.indexOf("📍")).trim())
      }
      console.log(`[useEffect hook] <IArtwork> data has been changed.`, record)
      if (record?.images && record?.images.length > 0) {
        const imageList: UploadFile[] = convertUrlsToUploadFiles(record?.images);
        setUploadFileList(imageList);
      }
      if (record?.color) {
        setManualColor(record?.color === 'BLACK' ? '' : record?.color);
      }
    }
    // if images property exists
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList (as opposed to the raw data.)
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<EyeOutlined />}
            onClick={(): void => goto("artworks", record?.id!)}
          >Show</Button>
          {defaultButtons}
        </>
      )}
      footerButtons={({ defaultButtons }) => (
        <>
          <Checkbox checked={needToCopy} onChange={(e) => {
            setNeedToCopy(e.target.checked)
          }}>
            연관 Auction 에 이미지 복사하기
          </Checkbox>
          {defaultButtons}
        </>
      )}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} form={form} onFinish={(values: any) => {

        console.log(values);
        const edition = values.edition ? values.edition.replaceAll(/[^/0-9]/g, '') : null;
        const artistId = values.artist.id;
        const ownerId = values.owner.id;
        const review = {
            artworkId: record.id,
            ko: values['review.ko'],
            en: values['review.en'],
          };
        delete values.artist;
        delete values.owner;
        delete values.review;

        formProps.onFinish && formProps.onFinish({
          ...values,
          edition,
          artistId,
          ownerId,
          manualColor: manualColor,
          images: uploadFileList.filter((v) => v != null).map((e) => e.url),
          review: review
        });

        if (needToCopy) {
          copyImages();
        }
        // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
        setTimeout(() => {
          if (returnUri.length > 0) {
            push(`../artworks?${returnUri}`);
          } else {
            goBack();
          }
        }, 500)
      }} layout="vertical">
        <Card
          type="inner"
          title="작품정보"
          style={{marginBottom: 24}}
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="작가명"
                name="artistName"
                rules={[{required: false}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="작품명"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="가로(mm)"
                name="width"
                rules={[{required: true}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="세로(mm)"
                name="height"
                rules={[{required: true}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="액자포함 가로(mm)"
                name="fullWidth"
                rules={[{required: false}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="액자포함 세로(mm)"
                name="fullHeight"
                rules={[{required: false}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="소재"
                name="medium"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="제작년도"
                name="producedIn"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="지연발송"
                name="shippableWithin"
              >
                <Select
                  options={[
                    { label: '없음', value: 0, },
                    { label: '1주', value: 1, },
                    { label: '2주', value: 2, },
                    { label: '3주', value: 3, },
                    { label: '4주', value: 4, },
                    { label: '5주', value: 5, },
                    { label: '6주', value: 6, },
                    { label: '7주', value: 7, },
                    { label: '8주', value: 8, },
                    { label: '9주', value: 9, },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="프레임"
                name="framing"
              >
                <Select
                  options={[
                    { label: '무', value: 'UNFRAMED', },
                    { label: '유', value: 'FRAMED', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="묶음배송"
                name="isCombinable"
              >
                <Select
                  options={[
                    { label: '가능', value: true, },
                    { label: '불가', value: false, },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="경매시작가"
                name="startingPrice"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="최소구매가"
                name="reservePrice"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="추정가"
                name="estimate"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="즉시구매가"
                name="buyItNowPrice"
                rules={[{required: false}]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="분류"
                name="category"
              >
                <Select
                  options={[
                    { label: '🎨 원화', value: 'ORIGINAL', },
                    { label: '🎴 에디션(LTD)', value: 'LIMITED_EDITION', },
                    { label: '🃏 에디션(OPN)', value: 'OPEN_EDITION', },
                    { label: '🪆 조각', value: 'SCULPTURE', },
                    { label: '✍🏼 일러스트', value: 'DRAWING', },
                    { label: '📸 사진', value: 'PHOTOGRAPHY', },
                    { label: '기타', value: 'OTHER', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="에디션"
                name="edition"
                rules={[{required: false}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="상태"
                name="condition"
              >
                <Select
                  options={[
                    { label: 'NEW', value: 'NEW', },
                    { label: 'A', value: 'A', },
                    { label: 'B', value: 'B', },
                    { label: 'C', value: 'C', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="색상"
                name="color"
              >
                <Select
                  options={[
                    { label: 'BEIGE', value: 'BEIGE', },
                    { label: 'BLACK', value: 'BLACK', },
                    { label: 'BLUE', value: 'BLUE', },
                    { label: 'BROWN', value: 'BROWN', },
                    { label: 'GRAY', value: 'GRAY', },
                    { label: 'GREEN', value: 'GREEN', },
                    { label: 'NAVY', value: 'NAVY', },
                    { label: 'ORANGE', value: 'ORANGE', },
                    { label: 'PINK', value: 'PINK', },
                    { label: 'PURPLE', value: 'PURPLE', },
                    { label: 'RED', value: 'RED', },
                    { label: 'WHITE', value: 'WHITE', },
                    { label: 'YELLOW', value: 'YELLOW', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                label="수동색상"
                name="manualColor"
              >
                <Radio.Group onChange={(e) => { setManualColor(e.target.value) }}>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FF0000' }} value="RED">{ manualColor === 'RED' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FFA500' }} value="ORANGE">{ manualColor === 'ORANGE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FFFF00' }} value="YELLOW">{ manualColor === 'YELLOW' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#F5F5DC' }} value="BEIGE">{ manualColor === 'BEIGE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#008000' }} value="GREEN">{ manualColor === 'GREEN' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#0000FF' }} value="BLUE">{ manualColor === 'BLUE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#000080' }} value="NAVY">{ manualColor === 'NAVY' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FFC0CB' }} value="PINK">{ manualColor === 'PINK' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#800080' }} value="PURPLE">{ manualColor === 'PURPLE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#A52A2A' }} value="BROWN">{ manualColor === 'BROWN' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FFFFFF' }} value="WHITE">{ manualColor === 'WHITE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#808080' }} value="GRAY">{ manualColor === 'GRAY' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#000000' }} value="BLACK">{ manualColor === 'BLACK' ? '✅': '▫' }</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="방향/사이즈"
              >
                <Image
                  width={24}
                  src={orientationImageUrl(record?.orientation)}
                  preview={false}
                />
                <Tag color='Blue' style={{ marginLeft: 16 }}>{record?.size}</Tag>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="보증서"
                name="certificate"
              >
                <Select
                  options={[
                    { label: '보증서있음', value: 'INCLUDED', },
                    { label: '보증서없음', value: 'NA', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="구매장소"
                name="place"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="구매년도"
                name="boughtIn"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="구매가"
                name="paidAmount"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="작가 아이디"
                name={["artist", "id"]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="오너 아이디"
                name={["owner", "id"]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Tag style={{ marginBottom: 4 }}>참고</Tag>
              <div style={{ fontSize: 12 }}>작가의 작품은 `작가 아이디`와 `오너(작가) 아이디`가 동일합니다.</div>
              <div style={{ fontSize: 12 }}>리셀 작품은 `작가 아이디` 와 `오너(리셀러) 아이디`가 다릅니다.</div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label={`${record?.title} 이미지 업로드`}>
                <Form.Item
                  name="images"
                  valuePropName="fileList"
                  getValueProps={ (value) => value }
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                  {/* <Upload { ...props }>
                    <p className="ant-upload-drag-icon">
                      { isUploading ? <Spin /> : <InboxOutlined style={{fontSize: 24}} /> }
                    </p>
                    <Button icon={<UploadOutlined />}>파일 선택</Button>
                  </Upload> */}

                  <ImageUploader
                    artwork={record}
                    bg={bg}
                    uploadFiles={uploadFileList}
                    uploadHandler={(items: UploadFile[]) => {
                      setUploadFileList(items);
                      // const imageUrls = items.filter((v) => v.url).map((v) => v.url);
                    }}
                  />

                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="합성여부 선택" initialValue={'nil'}>
                <Radio.Group value={bg} onChange={e => setBg(e.target.value)}>
                    <Radio value={'nil'}>합성안함</Radio>
                    <Radio value={'backpack'}>야외</Radio>
                    <Radio value={'coffee'}>거실(커피)</Radio>
                    <Radio value={'pot'}>거실(화분)</Radio>
                    <Radio value={'kid'}>아이(방)</Radio>
                    <Radio value={'corridor'}>아이(복도)</Radio>
                    <Radio value={'ladypot'}>여자(화분)</Radio>
                    <Radio value={'ladysofa'}>여자(소파)</Radio>
                    <Radio value={'guyblack'}>남자(블랙)</Radio>
                    <Radio value={'guywhite'}>남자(화이트)</Radio>
                    <Radio value={'dog'}>플리큐브</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="작품설명"
                name="body"
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown ?? ''}</ReactMarkdown>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="비고"
                name="note"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card type="inner" title="작품정보(ChatGPT)">
          <Row gutter={[16, 16]}>
            <Col span={24}>
            {
            record?.fullWidth > record?.width  &&  record?.fullHeight > record?.height
            ? `한국의 신진작가 "${record?.artistName}" 의 작품 "${record?.title}" 이다. 작품 소재는 "${record?.medium}" 이며, ${categoryName(record?.category)} 이다. 크기는 액자포함 ${Math.round(record?.fullWidth/10)}x${Math.round(record?.fullHeight/10)}cm 이고, 제작년도는 ${record?.producedIn}년 이다. 작가는 이 작품에 대해 다음과 같은 설명을 남겼다. "${trimmedBody}" 1) 이 작품의 화풍, 주제, 색감을 분석하여 5개의 한글 해쉬택을 추출하시오. 2) 작품의 상세 설명을 잡지사에 기고할 기고문으로 만드시오. 3) 기고문을 영문으로 번역하시오.`
            : `한국의 신진작가 "${record?.artistName}" 의 작품 "${record?.title}" 이다. 작품 소재는 "${record?.medium}" 이며, ${categoryName(record?.category)} 이다. 크기는 ${Math.round(record?.width/10)}x${Math.round(record?.height/10)}cm 이고, 제작년도는 ${record?.producedIn}년 이다. 작가는 이 작품에 대해 다음과 같은 설명을 남겼다. "${trimmedBody}" 1) 이 작품의 화풍, 주제, 색감을 분석하여 5개의 한글 해쉬택을 추출하시오. 2) 작품의 상세 설명을 잡지사에 기고할 기고문으로 만드시오. 3) 기고문을 영문으로 번역하시오.`
            }
            </Col>
            <Col span={12}>
              <Form.Item
                label="한글 리뷰"
                name="review.ko"
                initialValue={record?.review?.ko}
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="영문 리뷰"
                name="review.en"
                initialValue={record?.review?.en}
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Edit>
  );
};