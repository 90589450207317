import { message } from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { useState } from "react";

message.config({
  maxCount: 5, // 한 번에 표시되는 최대 메시지 개수
});

export const CsvImportForm: React.FC<IResourceComponentsProps> = () => {
  // useState hook
  const [file, setFile] = useState();
  const [array, setArray] = useState<any>([]);
  const fileReader = new FileReader();

  const { mutateAsync } = useCreate();

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const removeFirstLine = (inputString: string): string => {
    const carriageReturnIndex = inputString.indexOf('\n');
    if (carriageReturnIndex !== -1) {
        return inputString.substring(carriageReturnIndex + 1);
    } else {
        return inputString;
    }
  }

  const getInteger = (text: string): number => {
    if (!text) return 0;

    const v = parseInt(text.trim().replace(/["¸]+/g, '').replace(/[주]/g, '')); // 1주 2주... 때문에 추가
    return (isNaN(v)) ? 0 : v;
  }

  const getCategory = (text: string): string => {
    switch (text.trim()) {
      case `원화`:
        return 'ORIGINAL';
      case `에디션(LTD)`:
        return 'LIMITED_EDITION';
      case `에디션(OPN)`:
        return 'OPEN_EDITION';
      case `조각`:
        return 'SCULPTURE';
      case `일러스트`:
        return 'DRAWING';
      case `사진`:
        return 'PHOTOGRAPHY';
    }

    return 'OTHER'
  }

  const getOrientation = (w: number, h: number): string | null => {
    return (w === 0 || h === 0)
      ? null
      : w > h
        ? 'LANDSCAPE'
        : w < h
          ? 'PORTRAIT' : 'SQUARE'
  }

  const getSize = (w: number, h: number): string | null => {
    if (w === h) {
      if (w < 340) {
        return `S`;
      }
      if (w < 470) {
        return `M`;
      }
      if (w < 630) {
        return `L`;
      }
    } else {
      if ((w < 340 && h < 470) || (w < 470 && h < 340)) {
        return `S`;
      }
      if ((w < 470 && h < 630) || (w < 630 && h < 470)) {
        return `M`;
      }
      if ((w < 630 && h < 860) || (w < 860 && h < 630)) {
        return `L`;
      }
    }
    return `XL`;
  }

  const getNullableString = (text: string | null): string | null => {
    if (!text) {
      return null;
    }
    return text.trim().replace(/"/g, '').replace(/¸/g, ',').replace(/§/g, '\n');
  }

  const csvFileToArray = async (fullText: string) => {
    const textFirstLineRemoved = fullText; // removeFirstLine(fullText)
    const replacedString = textFirstLineRemoved.replace(/"([\w\W]*?)"/gi, function(match, token) {
      return match.replace(/\n/g, '§').replace(/,/g, '¸'); // wee need to preserve the original context w/ some special characters
    });

    const csvHeaderItems = replacedString.slice(0, replacedString.indexOf("\n")).split(",");
    const headers = csvHeaderItems.map((v) => {
      const w = v.replace(/[^\w가-힣]/g, '');
      return w;
    })

    console.log(headers);
    const validLabels = [
      '구분', 
      'ID', 
      '작품명', 
      '작가명', 
      '추정가', 
      '시작가', 
      '즉구가', 
      '제작년도', 
      '가로MM', 
      '세로MM', 
      '액자가로MM', 
      '액자세로MM', 
      '액자', 
      '묶음배송', 
      '재료', 
      '분류', 
      '에디션넘버NN', 
      '지연발송', 
      '작품정보고지'
    ];
    const indexMap = new Map();
    // validLabels 순회하면서 input 배열에서 인덱스 찾기
    validLabels.forEach(label => {
      const index = headers.indexOf(label); // input 배열에서 해당 label의 인덱스 찾기
      if (index !== -1) { // 존재하는 경우에만 Map에 추가
        indexMap.set(label, index);
      }
    });
    if (indexMap.size !== 19) {
      message.error('CSV 포맷이 잘못되어 인식불가합니다. 첫번째 줄이 빈칸으로 남아있는지 확인하세요!');
      return;
    }

    const csvRows = replacedString.slice(replacedString.indexOf("\n") + 1).split("\n");
    const validRows = csvRows.filter((v) => v && !v.startsWith(',,,,,,,,,,')); // 쓰레기 빈칸 삭제

    // console.log('csv header => ', csvHeader)
    console.log('csv rows => ', validRows)
    const values = validRows.map((v) => {
      const values = v.split(",");

      // extract 19 different columns
      const artistId = getInteger(values[indexMap.get('ID')]);
      const artworkType = getNullableString(values[indexMap.get('구분')]);
      const title = getNullableString(values[indexMap.get('작품명')]);
      const artistName = getNullableString(values[indexMap.get('작가명')]);
      const estimate = getInteger(values[indexMap.get('추정가')]);
      const startingPrice = getInteger(values[indexMap.get('시작가')]);
      const buyItNowPrice = getInteger(values[indexMap.get('즉구가')]);
      const producedIn = getInteger(values[indexMap.get('제작년도')]);
      const width = getInteger(values[indexMap.get('가로MM')]);
      const height = getInteger(values[indexMap.get('세로MM')]);
      const frameWidth = getInteger(values[indexMap.get('액자가로MM')]);
      const frameHeight = getInteger(values[indexMap.get('액자세로MM')]);
      const framing = getNullableString(values[indexMap.get('액자')]);
      const isCombinable = getNullableString(values[indexMap.get('묶음배송')]);
      const medium = getNullableString(values[indexMap.get('재료')]);
      const category = getNullableString(values[indexMap.get('분류')]);
      const edition = getNullableString(values[indexMap.get('에디션넘버NN')]);
      const delay = getInteger(values[indexMap.get('지연발송')]);
      const body = getNullableString(values[indexMap.get('작품정보고지')]);

      // extra
      const fullWidth = (frameWidth > 0 && frameWidth > width) ? frameWidth : width;
      const fullHeight = (frameHeight > 0 && frameHeight > height) ? frameHeight : height;

      const payload = {
        title: title ?? 'n/a',
        artistName: artistName ?? 'n/a',
        estimate: estimate,
        startingPrice: startingPrice,
        buyItNowPrice: buyItNowPrice,
        producedIn: producedIn > 0 ? producedIn : null,
        shippableWithin: delay,
        width: width,
        height: height,
        fullWidth: fullWidth,
        fullHeight: fullHeight,
        orientation: getOrientation(width, height),
        size: getSize(fullWidth, fullHeight),
        framing: framing === 'O' ? 'FRAMED' : 'UNFRAMED',
        isCombinable: isCombinable === 'O' ? true : false,
        medium: medium,
        category: getCategory(category ?? ''),
        edition: edition,
        body: body,
      };
      if (artworkType === `리셀`) {
        return { ...payload, ownerId: artistId, }
      } else {
        return { ...payload, artistId: artistId, ownerId: artistId, }
      }
    });

    console.log(`~~~~~~~~~~~`, values);

    const sortedItems = values.sort((a, b) => {
      const upperA = a.artistName.toUpperCase();
      const upperB = b.artistName.toUpperCase();
      return (upperA < upperB) ? -1 : (upperA > upperB) ? 1 : 0;
    });
      
    console.log(`sorted Items: `, sortedItems);

    await uploadItems(sortedItems);
  };

  const uploadItems = async (items: any[]) => {
    for (const item of items) {
      try {
        const data = await mutateAsync({
          resource: 'artworks',
          values: item,
        });
        message.success(`성공: ${item.title}`)
      } catch (error) {
        message.error(`실패: ${error}`);
        break;
      }
    }
  };

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = async function (event: any) {
        const text = event.target.result;
        await csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1>CSV 파일로 입력.</h1>
        <form>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          />

          <button
            onClick={(e) => {
              handleOnSubmit(e);
            }}
          >
            IMPORT CSV
          </button>
        </form>

        <br />

        <table>
          <thead>
            <tr key={"header"}>
              {headerKeys.map((key) => (
                <th key={`key-${key}`}>{key}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {array.map((item: any) => (
              <tr key={item.id}>
                {Object.values(item).map((val: any) => (
                  <td>{val}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
