import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Row,
  Show,
  Space,
  Typography
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useDelete, useShow } from "@pankod/refine-core";
import { IEvent } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;

export const EventShow: React.FC<IResourceComponentsProps> = () => {
  // useShow
  const { queryResult } = useShow<IEvent>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  // useDelete
  const { mutate } = useDelete<IEvent>();
  const deleteGrant = (id: number) => {
    mutate(
      {
        resource: `events/${record?.id}`,
        id: id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };


  const getLocation = (v: string) => {
    switch (v) {
      case 'center':
        return '정중앙';
      case 'bottom-left':
        return '하단 왼쪽';
      case 'bottom-right':
        return '하단 오른쪽';
      case 'bottom-center':
        return '하단 중앙';
      case 'top-left':
        return '상단 왼쪽';
      case 'top-right':
        return '상단 오른쪽';
      case 'top-center':
        return '상단 중앙';
      default:
        return '미지정';
    }
  }
  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  // useEffect(() => {
  //   console.log(`record has been changed; ${record}`)
  //   setGrants(record?.grants)
  // }, [record]);

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card type="inner" title="이벤트 정보">
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>이벤트 제목</Title>
            <>
            <Text>{ record?.title }</Text>
            </>
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>이벤트 부제목</Title>
            <>
            <Text>{ record?.subtitle ?? '(내용없음)' }</Text>
            </>
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>생성일</Title>
            <Space>
              {record?.createdAt && <ReactTimeAgo date={Date.parse(record?.createdAt)} locale="ko" />}
            </Space>
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>수정일</Title>
            <Space>
              {record?.updatedAt && <ReactTimeAgo date={Date.parse(record?.updatedAt)} locale="ko" />}
            </Space>
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>이미지 preview</Title>
            {
              record?.image
              ? <Image
                  src={ record?.image ?? 'https://cdn.fleaauction.world/images/event.png'}
                  width={192}
                  height={192}
                />
              : <Avatar size={64} shape="square" />
            }
            {/* {
              record?.mobileImage
              ? <Image
                  src={ record?.mobileImage ?? 'https://cdn.fleaauction.world/images/event.png'}
                  width={64}
                  height={64}
                />
              : <Avatar size={64} shape="square" />
            } */}
          </Col>
          <Col span={6}>
              <Title level={5} style={{fontSize: 11}}>버튼 preview</Title>
              {
                record?.buttons?.map((v, i) => (<div key={`key-${i}`}>
                <div>버튼위치: {getLocation(v.location)}</div>
                {
                  v.buttonType === 'info' &&
                  <Button style={{
                    'backgroundColor' : '#6ad1c3',
                    'color': 'black',
                    'width': '100%',
                  }}>{v.label}</Button>
                }
                {
                  v.buttonType === 'success' &&
                  <Button style={{
                    'backgroundColor' : 'green',
                    'color': 'white',
                    'width': '100%',
                  }}>{v.label}</Button>
                }
                {
                  v.buttonType === 'error' &&
                  <Button style={{
                    'backgroundColor' : 'red',
                    'color': 'white',
                    'width': '100%',
                  }}>{v.label}</Button>
                }
                {
                  v.buttonType === 'warn' &&
                  <Button style={{
                    'backgroundColor' : 'yellow',
                    'color': 'black',
                    'width': '100%',
                  }}>{v.label}</Button>
                }
                </div>))
              }
          </Col>
        </Row>
      </Card>
    </Show>
  )
};

