import {
  Avatar,
  Button,
  DatePicker,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  message,
  NumberField,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField, useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import axios from 'axios';
import { orderStatusColor, orderStatusName, orderTypeColor, orderTypeName, shippingStatusColor, shippingStatusName } from "helpers/order";
import { IOrder } from "interfaces";
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import ReactTimeAgo from "react-time-ago";
import { ACCESS_TOKEN } from "../../constants";

const { Option } = Select;
const { Link } = routerProvider;
const { RangePicker } = DatePicker;

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [target, setTarget] = useState<string>('delivery');
  const [csvData, setCsvData] = useState<any>([]);

  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  const deliveryCsvHeaders = [
    { label: '기힉전명', key: "subtitle", },
    { label: '작품명', key: "title", },
    { label: '작가명', key: "artistName", },
    { label: '고객ID', key: "userId", },
    { label: '고객명', key: "realname", },
    { label: '전화번호', key: "phone", },
    { label: '전체주소', key: "fullAddress", },
    { label: '주소', key: "address", },
    { label: '상세주소', key: "addressDetail", },
    { label: '우편번호', key: "postalCode", },
    { label: '요청사항', key: "request", },
    { label: '가격', key: "price", },
    { label: '배송비', key: "shipping", },
    { label: '배송설명', key: "shippingComment", },
    { label: '생성일', key: "createdAt", },
    { label: '결제일', key: "paidAt", },
    { label: '상태', key: "status", },
  ];

  const certificateCsvHeaders = [
    { label: 'ARTIST', key: "artist", },
    { label: 'TITLE', key: "title", },
    { label: 'PHOTO', key: "photo", },
    { label: 'YEAR', key: "year", },
    { label: 'DIMENSION', key: "dimension", },
    { label: 'MATERIAL', key: "material", },
    { label: 'CATEGORY', key: "category", },
    { label: 'EDITION', key: "edition", },
    { label: 'SERIAL', key: "serial", },
    { label: 'DATE', key: "date", },
    { label: 'STATUS', key: "status", },
  ];

  const stickerCsvHeaders = [
    { label: 'ARTIST', key: "artist", },
    { label: 'TITLE', key: "title", },
    { label: 'PHOTO', key: "photo", },
    { label: 'YEAR', key: "year", },
    { label: 'STATUS', key: "status", },
  ];

  const { tableProps, sorter } = useTable<IOrder>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  const loadDeliveryData = async (t: string, from: string, to: string) => {
    if (!from || !to) {
      message.warn(`생성할 날짜 범위를 지정해주세요.`)
    }
    try {
      axios({
        method: 'get',
        url: `${apiUrl}/orders/${t}?from=${from}&to=${to}`,
        headers: headers,
      }).then((data) => {

        console.log(data.data);
        if (data.data.length > 0) {
          message.info(`CSV 데이터 ${data.data.length} 건이 검색되었습니다. 다운로드 하세요.`)
        } else {
          message.info(`해당 기간의 데이터가 없습니다.`)
        }
        setCsvData(data.data);
      })
    } catch (e) {
      message.error('데이터 조회 오류')
    }
  }

  const handleRangeChange = async (value: any) => {
    const [fromVal, toVal] = value;
    const fromDate = fromVal.toISOString().split('T')[0];
    const toDate = toVal.toISOString().split('T')[0];
    setFrom(fromDate);
    setTo(toDate);
    await loadDeliveryData(target, fromDate, toDate);
  }

  const handleOptionChange = async (value: string) => {
    setTarget(value)
    if (from && to) {
      setCsvData([])
      await loadDeliveryData(value, from, to);
    }
  }

  return (
    <List
      headerButtons={() => (
        <>
        <Select
          value={target}
          style={{ width: 140, marginRight: 10 }}
          onChange={handleOptionChange}
        >
          <Option value="delivery">택배용 CSV</Option>
          <Option value="certificate">보증용 CSV</Option>
          <Option value="sticker">폼택용 CSV</Option>
        </Select>
        <RangePicker
          onChange={handleRangeChange}
        />
        {
          csvData.length > 0 && 
          <CSVLink
            data={csvData}
            headers={
              (target === `delivery`)
              ? deliveryCsvHeaders
              : ( target === `certificate` ? certificateCsvHeaders : stickerCsvHeaders )
            }
            filename={`orders_${target}_${from}_${to}.csv`}
          ><Button>다운로드</Button>
          </CSVLink>
        }
        </>
      )}
    >
      <Table
        {...tableProps}
        dataSource={(tableProps?.dataSource as any)?.data}
        rowKey="id"
      >
        <Table.Column<IOrder>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IOrder>
          dataIndex="image"
          title="작품사진"
          render={(_, record) => (
            <Link to={`/orders/show/${record?.id}`}>
              {
                record &&
                <Avatar
                  size={64}
                  shape="square"
                  src={record?.image}
                  alt={record?.title}
                />
              }
            </Link>
          )}
        />
        <Table.Column<IOrder>
          dataIndex="title"
          key="title"
          title="작품명/작가명/기획전명"
          render={(_, record) => <>
            <div><TextField value={`${record.title} (작가: ${record.auction?.artistName})`} /></div>
            <div><TagField value={record.subtitle} /></div>
          </>}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IOrder>
          dataIndex="price"
          key="price"
          title="판매가"
          render={(_, record) => <TextField value={`${record?.price.toLocaleString()}원`} />}
          defaultSortOrder={getDefaultSortOrder("price", sorter)}
          sorter
        />
        <Table.Column<IOrder>
          dataIndex="orderType"
          key="orderType"
          title="분류"
          render={(value) => <TagField color={orderTypeColor(value)} value={orderTypeName(value)} />}
          defaultSortOrder={getDefaultSortOrder("orderType", sorter)}
          sorter
        />
        <Table.Column<IOrder>
          dataIndex="createdAt"
          key="createdAt"
          title="종료시각"
          render={(value) => {
            return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<IOrder>
          dataIndex="paidAt"
          key="paidAt"
          title="결제시각"
          render={(_, record) => {
            return record?.payment?.paidAt
            ? (<ReactTimeAgo date={Date.parse(record?.payment?.paidAt)} locale="ko" />)
            : <TagField value="기록없음" />;
          }}
          defaultSortOrder={getDefaultSortOrder("paidAt", sorter)}
          sorter
        />
        <Table.Column<IOrder>
          dataIndex="orderStatus"
          key="orderStatus"
          title="결제상태"
          render={(value) => <TagField color={orderStatusColor(value)} value={orderStatusName(value)} />}
          defaultSortOrder={getDefaultSortOrder("orderStatus", sorter)}
          sorter
        />
        <Table.Column<IOrder>
          dataIndex="shippingStatus"
          key="shippingStatus"
          title="배송상태"
          render={(value) => <TagField color={shippingStatusColor(value)} value={shippingStatusName(value)} />}
          defaultSortOrder={getDefaultSortOrder("orderStatus", sorter)}
          sorter
        />
        <Table.Column<IOrder>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
