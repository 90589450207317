import {
  Avatar,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  Icons,
  Image,
  Input,
  List,
  NumberField,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { VerticalLeftOutlined } from '@ant-design/icons';
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { categoryName, firstImageUrl } from "helpers/artwork";
import { avatarUrl } from "helpers/user";
import { IArtwork } from "interfaces";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactTimeAgo from "react-time-ago";

const { CloseOutlined } = Icons;
const { Option } = Select;
const { Link } = routerProvider;
const { useLocation } = routerProvider;

export const ArtworkList: React.FC<IResourceComponentsProps> = () => {
  const loc = useLocation();
  const params = loc.search.substring(1).split('&');
  const apiUrl = useApiUrl();
  const artworkId = useRef<number>();





  const { tableProps, sorter, filters, setFilters } = useTable<IArtwork>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  //!!!! Gets the current filter values for the fields
  const currentFilterValues = useMemo(() => {
    // Filters can be a LogicalFilter or a ConditionalFilter. ConditionalFilter not have field property. So we need to filter them.
    // We use flatMap for better type support.
    const logicalFilters = (filters ?? []).flatMap((item) =>
      "field" in item ? item : [],
    );

    return {
      title: logicalFilters.find((item) => item.field === "title")?.value || "",
      id: logicalFilters.find((item) => item.field === "id")?.value || "",
      category:
        logicalFilters.find((item) => item.field === "category")?.value || "",
    };
  }, [filters]);

  //?-------------------------------------------------------------------------//
  //? useEffect
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    console.log(`use Effect`)
  }, []);

  // const onPageNumberChangeHandler = (e: any) => {
  //   const v = e.target?.value;
  //   if (+v > 0) {
  //     artworkId.current = +v;
  //   }
  // }
  // const moveToPage = async (id: number) => {
  //   const res = await fetch(`${apiUrl}/artworks/pages/closest?${params[0]}&id=${artworkId.current}`)
  //   const { data: page } = (await res.json()) as { data: number }
  //   const paramString = params.map((v) => {
  //     if (v.startsWith('current=')) {
  //       return `current=${page}`;
  //     }
  //     return v;
  //   }).join('&')
  //   const targetUrl = `${window.location.origin}${loc.pathname}?${paramString}`
  //   window.location.replace(targetUrl);
  // }




  return (
    <List
      headerButtons={({ defaultButtons }) => (
      <>
        <Input
          placeholder="제목"
          value={currentFilterValues.title}
          allowClear={true}
          onChange={(e) => {
            setFilters([
              {
                field: "title",
                operator: "contains",
                value: !!e.currentTarget.value
                  ? e.currentTarget.value
                  : undefined,
              },
              {
                field: "category",
                operator: "eq",
                value: undefined,
              },
            ]);
          }}
        />
        <select
          value={currentFilterValues.category}
          style={{ width: '120px', padding: '4px' }}
          onChange={(e) =>
            setFilters(
              [
                {
                  field: 'title',
                  operator: 'contains',
                  value: undefined,
                },
                {
                  field: "category",
                  operator: "eq",
                  value: !!e.currentTarget.value
                    ? e.currentTarget.value
                    : undefined,
                },
              ],
              "replace",
            )
          }
        >
          <option value="">전체 분류</option>
          <option value="ORIGINAL">원화</option>
          <option value="LIMITED_EDITION">판화(LTD)</option>
          <option value="OPEN_EDITION">판화(OPN)</option>
          <option value="SCULPTURE">조각</option>
          <option value="DRAWING">일러스트</option>
          <option value="PHOTOGRAPHY">사진</option>
          <option value="OTHER">기타</option>
        </select>
        {/* <Input placeholder="Artwork #" onChange={onPageNumberChangeHandler} />
        <Button
          icon={<VerticalLeftOutlined />}
          onClick={async () => {
            if (artworkId.current && artworkId.current > 0) {
              console.log(`${apiUrl}/artworks/pages/closest?${params[0]}&id=${artworkId.current}&`)
              await moveToPage(artworkId.current);
            }
          }}
        >페이지 이동</Button> */}
        { defaultButtons }
        </>
      )}
    >
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="image"
          title="작품사진"
          render={(_, record) => (
            <Link to={`/artworks/show/${record?.id}`}>
              <Image
                width={72}
                height={72}
                // shape="square"
                src={firstImageUrl(record?.images)}
                alt={record?.title}
                style={{
                  // width: 100,
                  maxHeight: '100px',
                  objectFit: 'scale-down',
                }}
              />
            </Link>
          )}
        />
        <Table.Column<IArtwork>
          dataIndex="artistName"
          key="artistName"
          title="작가"
          render={(_, record) => {
            return record?.artist
            ? 
              <Link to={`/artists/show/${record?.artist?.id}`}>
                {
                  record && record.artist && record.artist.user &&
                  <Avatar
                    size={32}
                    shape="square"
                    src={avatarUrl(record?.artist?.user?.avatar)}
                    alt={record?.owner?.user?.username}
                  />
                }
                <TextField style={{marginLeft: 8}} value={record?.artist?.name} />
              </Link>
            : <TextField value={record?.artistName} />
          }}
          defaultSortOrder={getDefaultSortOrder("artistName", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="title"
          key="title"
          title="제목"
          render={(_, record) => <>
            <TagField value={record?.size} style={{ marginRight: 4 }} />
            <TextField value={record?.title} style={{fontWeight: "bold"}} />
            <div>{ record?.place ?
              record.place === 'atelier' ?
                <TextField style={{ fontSize: 10 }} type="danger" value={`판매요청: 작가요청작`} />
                : <TextField style={{ fontSize: 10 }} type="danger" value={`판매요청: ${record.boughtIn ?? '구매년도 미상'} ${record.place} 구매`} />
                : <TextField style={{ fontSize: 10 }} value="회사입력" /> }</div>
          </>}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="startingPrice"
          key="startingPrice"
          title="시작가"
          render={(value) => <TextField value={value.toLocaleString()} />}
          defaultSortOrder={getDefaultSortOrder("startingPrice", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="estimate"
          key="estimate"
          title="추정가"
          render={(value) => <TextField value={value.toLocaleString()} />}
          defaultSortOrder={getDefaultSortOrder("estimate", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="category"
          key="category"
          title="분류"
          render={(value) => <TextField value={categoryName(value)} />}
          defaultSortOrder={getDefaultSortOrder("category", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="updatedAt"
          key="updatedAt"
          title="최근수정"
          render={(value) => <ReactTimeAgo date={Date.parse(value)} locale="ko" />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
